import { TeacherPin } from '@lessonup/pins-shared';
import { TeacherCreditsContextProvider } from '@lessonup/teacher-modern';
import { Lesson } from '@lessonup/teaching-core';
import { Banner } from '@lessonup/ui-components';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { IndexedChannelInfo, LessonDetailsAccessTypes, Licenses, UserContext } from '../../../../shared-core/domain';
import { loginRoute, registerRoute } from '../../../../shared-core/services/app/searchRoutes';
import { loggedInUser } from '../../../redux/selectors';
import { incentiveCounter } from '../../../services/IncentiveCounter/IncentiveCounterService';
import { domainLicenseToLicenseStatus } from '../helpers/domainLicenseToLicenseStatus';
import LessonOverviewV2, { AddPinToActiveLesson } from './LessonOverviewV2/LessonOverviewV2';

export interface LessonPageV2ViewProps {
  className?: string;
  lesson: Lesson | undefined;
  pins: TeacherPin[];
  channel: IndexedChannelInfo | undefined;
  onLessonSave: (lesson: Lesson) => void;
  isLessonSaved: boolean;
  accessTypes: LessonDetailsAccessTypes;
  onPinSelected: (pinId: string) => void;
  navigateToLesson: (newTab?: boolean) => void;
  shareLesson: () => void;
  preselectedPin?: string;
  backNavigation?: React.JSX.Element | undefined;
  flagLessonButton?: React.JSX.Element | undefined;
  channelUrl: (slug: string) => string;
  channelFollowed: boolean;
  userIsLoggedIn: boolean;

  addPin?: AddPinToActiveLesson | undefined;
  activeLesson?: UserContext['activeLesson'];
  activeDocument?: UserContext['activeDocument'];
  embedLesson?: boolean;
  currentLicense: Licenses.Status | undefined;
  eligibleToTeachWithoutCredits: boolean;
}

const LessonPageViewV2: React.FC<LessonPageV2ViewProps> = (props: LessonPageV2ViewProps) => {
  const {
    className,
    lesson,
    pins,
    channel,
    backNavigation,
    channelUrl,
    addPin,
    embedLesson,
    eligibleToTeachWithoutCredits,
  } = props;
  const user = useSelector(loggedInUser());

  const [showBannerBool, setShowBannerBool] = useState(false);

  const { t } = useTranslation('lessonPageView');

  const query = useLocation().search;

  const incentiveExperimentVariant = incentiveCounter.incentiveCounterExperimentVariant();

  useEffect(() => {
    const urlParams = new URLSearchParams(query);
    const shouldShowPrintDialog = urlParams.get('print');
    shouldShowPrintDialog && window.print();
  }, [query]);

  useEffect(() => {
    setShowBannerBool(true);
  }, []);

  const ParagraphComponent = () => {
    return (
      <Trans
        t={t}
        i18nKey="signUpOrLoginToContinue"
        components={[<Link to={registerRoute('credentials')} />, <Link to={loginRoute('form')} />]}
      />
    );
  };

  const isInDefaultVariant = incentiveExperimentVariant === 'default';

  if (!lesson) return null;

  const showBanner = () => {
    if (user || isInDefaultVariant || embedLesson || eligibleToTeachWithoutCredits) {
      return false;
    }
    return true;
  };

  return (
    <div className={classNames('lesson-page-section', 'page-center', className)}>
      {showBannerBool && showBanner() && (
        <Banner
          heading={
            incentiveExperimentVariant === 'medium'
              ? t('teachingLessonsIsLimitedTo7')
              : t('noTeachingLessonsWithoutAccount')
          }
          alignment="center"
          paragraph={ParagraphComponent()}
        ></Banner>
      )}
      {backNavigation}
      <TeacherCreditsContextProvider
        currentLicense={domainLicenseToLicenseStatus(props.currentLicense)}
        skipCheck={eligibleToTeachWithoutCredits}
      >
        <LessonOverviewV2
          channel={channel}
          lesson={lesson}
          pins={pins}
          onLessonSave={props.onLessonSave}
          onPinSelected={props.onPinSelected}
          preselectedPin={props.preselectedPin}
          navigateToLesson={props.navigateToLesson}
          shareLesson={props.shareLesson}
          isLessonSaved={props.isLessonSaved}
          accessTypes={props.accessTypes}
          flagLessonButton={props.flagLessonButton}
          channelUrl={channelUrl}
          channelFollowed={props.channelFollowed}
          userIsLoggedIn={props.userIsLoggedIn}
          addPin={addPin}
          activeLesson={props.activeLesson}
          activeDocument={props.activeDocument}
          embedLesson={props.embedLesson}
          currentLicense={props.currentLicense}
          eligibleToTeachWithoutCredits={eligibleToTeachWithoutCredits}
        />
      </TeacherCreditsContextProvider>
    </div>
  );
};

export default LessonPageViewV2;
