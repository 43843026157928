import { logout } from '@lessonup/intercom';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ExtendedChannelInfo, IndexedChannelInfo, LessonDetails } from '../../../shared-core/domain';
import { embedRoute } from '../../../shared-core/services/app/searchRoutes';
import { FontsSingleton } from '../../../shared-core/services/fonts/FontsSingleton';
import transformServingImage from '../../../shared-core/utils/transformServingImage';
import { lessonStore } from '../../redux/selectors';
import LessonPage from '../lesson/LessonPage';

type Props = RouteComponentProps & {
  selectionId: string;
  preselectedPin?: string;
};

const EmbedLessonPage: React.FC<Props> = ({ history, selectionId, preselectedPin }) => {
  const { lessonDetails, lessonId } = useSelector(lessonStore());
  const channelInfo = lessonId === selectionId && lessonDetails ? lessonDetails.channel : undefined;
  const extendedInfo = channelInfo && IndexedChannelInfo.isExtendedInfo(channelInfo) ? channelInfo : undefined;

  if (typeof window !== 'undefined') {
    logout();
  }

  const embedLesson = true;
  const handlePinSelected = (pinId: string) => {
    history.replace({
      pathname: embedRoute(selectionId, pinId),
    });
  };

  if (!selectionId) {
    return null;
  }

  const channel = lessonDetails?.channel;
  const extraStyleClasses: string[] = [];
  if (channel && IndexedChannelInfo.isExtendedInfo(channel)) {
    if (channel.style?.font) {
      extraStyleClasses.push(`lesson-font-inherit font-${channel.style.font}`);
      FontsSingleton.add([channel.style.font]);
    }
  }

  return (
    <div className={classNames('iframe-container', extraStyleClasses)}>
      {extendedInfo && lessonDetails ? <EmbedHeader extendedInfo={extendedInfo} lessonDetails={lessonDetails} /> : null}
      <LessonPage
        lessonId={selectionId}
        preselectedPin={preselectedPin}
        handlePinSelected={handlePinSelected}
        backUrl={''}
        backNavigation={undefined}
        embedLesson={embedLesson}
      />
    </div>
  );
};

export default EmbedLessonPage;

interface EmbedHeaderProps {
  extendedInfo: ExtendedChannelInfo;
  lessonDetails: LessonDetails;
}

const EmbedHeader: React.FC<EmbedHeaderProps> = ({ extendedInfo, lessonDetails }) => {
  const iconStyle = {
    backgroundImage: extendedInfo.icon && `url(${transformServingImage(extendedInfo.icon, { size: 150 })})`,
  };

  return (
    <div className="chan-header iframe-header">
      <div className="visual" style={bannerStyle(extendedInfo, lessonDetails)}></div>
      <div className="glasspane"></div>
      <div className="channel-icon-and-name">
        <div className="header-icon" style={iconStyle}></div>
        <EmbedNameDescription extendedInfo={extendedInfo} lessonDetails={lessonDetails} />
      </div>
    </div>
  );
};

function bannerStyle(extendedInfo: ExtendedChannelInfo, lessonDetails: LessonDetails): React.CSSProperties {
  if (lessonDetails.lesson.header === 'coverbar') {
    return {
      backgroundImage:
        lessonDetails.lesson.cover && `url(${transformServingImage(lessonDetails.lesson.cover, { size: 1280 })})`,
      backgroundPosition: `center ${lessonDetails.lesson.coverFocusY}%`,
    };
  }

  return {
    backgroundImage: extendedInfo.cover && `url(${transformServingImage(extendedInfo.cover, { size: 1280 })})`,
    backgroundPosition: `center ${extendedInfo.coverFocus}%`,
  };
}

const EmbedNameDescription: React.FC<EmbedHeaderProps> = ({ extendedInfo, lessonDetails }) => {
  return (
    <div className="channel-name-description">
      <div className="channel-name">{lessonDetails.lesson.name}</div>
    </div>
  );
};
