import { LessonAttachment } from '@lessonup/teaching-core';
import { Button } from '@lessonup/ui-components';
import { AppError, triggerDownloadFromFetchResponse } from '@lessonup/utils';
import React, { useState } from 'react';
import { showDefaultErrorNotification } from '../../../../../shared-core/client/utils/modals';
import { Api } from '../../../../../shared-core/domain/api/LessonupApi';
import { useAppServices } from '../../../../components/appServices/AppServicesContext';

interface Props {
  lessonId: string;
  attachment: LessonAttachment;
}

export const DownloadAttachment: React.FC<Props> = ({ lessonId, attachment }) => {
  const { lesson } = useAppServices();
  const [loading, setLoading] = useState(false);

  async function handleClick() {
    try {
      if (!attachment.uploadId) return getFallbackDownloadLink(attachment);
      setLoading(true);
      const response = await lesson.lessonDownloadAttachment({ lessonId, uploadId: attachment.uploadId });
      await downloadFile(response);
    } catch (error) {
      showDefaultErrorNotification();
    } finally {
      setLoading(false);
    }
  }

  async function downloadFile({ url, contentDispositionHeader }: Api.LessonDownloadAttachmentResponse) {
    const uploadFile = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Disposition': contentDispositionHeader,
      },
    });
    return triggerDownloadFromFetchResponse(uploadFile, attachment.name);
  }

  function getFallbackDownloadLink(attachment: LessonAttachment): void {
    if (!attachment.url) throw new AppError('unexpected-data', 'Attachment cannot be downloaded');

    // Some older attachments do not have an uploadId. For these attachments, we use the API Media URL.
    window.open(attachment.url, '_blank');
  }

  return (
    <Button buttonType="neutral" showStroke key={attachment.uploadId} onClick={handleClick} loading={loading}>
      {attachment.name}
    </Button>
  );
};
