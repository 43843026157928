import React from 'react';
import { withRouter } from 'react-router';
import transformServingImage from '../../../shared-core/utils/transformServingImage';
import { htmlDecode } from '../../utils/htmlDecode';
import AnchorOrLink from '../AnchorLink/AnchorOrLink';
import './ThumbBanner.less';

interface Props {
  content: string;
  bannerLink: string;
  icon?: string | undefined;
  history: any;
}

class ThumbBanner extends React.PureComponent<Props> {
  public constructor(props) {
    super(props);
  }

  public render() {
    const { bannerLink, content, icon } = this.props;
    const Content = <span className="thumb-banner-text">{htmlDecode(content)}</span>;

    return (
      <AnchorOrLink url={bannerLink} className={'thumb-banner' + (this.props.icon ? ' thumb-banner--icon' : '')}>
        <BannerLabelIcon icon={icon} title={content} />
        {Content}
      </AnchorOrLink>
    );
  }
}
export default withRouter(ThumbBanner);

const ICON_SIZE = 14;
const MAX_SCALING = 3;

const BannerLabelIcon = ({ icon, title }: { icon: string | undefined; title: string }) => {
  if (!icon) {
    return null;
  }

  return (
    <img
      className="thumb-banner__icon"
      src={transformServingImage(icon, { size: ICON_SIZE * MAX_SCALING })}
      width={ICON_SIZE}
      height={ICON_SIZE}
      alt={title}
    />
  );
};
