import { EduSystem } from '@lessonup/teaching-core';
import { AppError, hasExpired, isBefore } from '@lessonup/utils';
import _, { isNumber, isUndefined, trim } from 'lodash';
import { getDomainFromEmail, stringToDateUnsafe } from '../../utils';
import { Country } from '../country/Country';
import { LanguageKey } from '../language';
import { ProductWithSource } from '../products';
import { Currency } from '../subscription';
import { MongoUser } from '../user';

function check(value: any, type?: 'string' | 'number' | 'boolean' | 'date' | 'object'): boolean {
  if (type === 'date' && (value instanceof Date || new Date(value).getDate() !== NaN)) {
    return true;
  }
  if (type && typeof value !== type) {
    throw new AppError('unexpected-data', `Expected input to be of different type`, { value, type });
  }
  return !!value;
}

export type OrganizationId = string;
export type AgreementId = string;
type ID = string;

/**
 * Organizations are a construct we use to group users. They can represent schools, publishers, or other entities.
 * For more information see: https://lessonup.atlassian.net/wiki/x/AgBZKg
 */
export interface Organization {
  _id: OrganizationId;
  slug: string;
  user?: string;
  products?: Organization.Product[];
  name: string;
  settings: Organization.Settings;
  license: Organization.License;
  address?: Organization.Address;
  contact?: Organization.Contact;
  featureFlags?: string[];
  language?: LanguageKey;
  currency: Currency.ISOCurrencyLowerCased;
  parentOrganization?: string;
  locations?: Organization.Location[];
  productHistory?: Organization.Product[];
  creationDate: Date;
  modifiedDate?: Date;
  origin?: Organization.Origin;
  /**
   * Also check domain rules on login, instead of only on registration
   */
  checkDomainRulesOnLogin?: boolean;
  surfconext?: Organization.SurfConext;
  // do we still use this, can go i think?
  sales?: {
    assumedUsers: string[];
    isLead: boolean;
    [key: string]: any;
  };
  curricula?: string[];
  schoolType?: EduSystem.AllSchoolTypes;
  sharedExplorers?: ID[];
}

export namespace Organization {
  export type Origin = 'admin' | 'dpaFlow';

  export interface Product {
    _id: string;
    amount?: number; // not used;
    startDate?: Date;
    endDate?: Date | null;
  }

  export interface Address {
    name: string;
    street: string;
    zipcode: string;
    city: string;
    country: string;
  }

  export type Settings = {
    multiLocation?: boolean;
    isSchool?: boolean;
    isSuper?: boolean;
    isPublisher?: boolean;
    canCreateVouchers?: boolean;
    autoJoinWorkfolder?: boolean;
    manageStudents?: boolean;
    inviteMailType?: string;
    manageChildLicenses?: boolean;
    hasPage?: boolean;
    formalLanguage?: boolean;
  };

  export interface PrivacyContactDetails {
    name: string;
    email: string;
    position: string;
  }

  export interface Contact {
    department?: string;
    email?: string;
    phone?: string;
    firstname?: string;
    lastname?: string;
    address?: string;
    zipcode?: string;
    city?: string;
    country?: Country.CountryCode;
    invoiceEmail?: string; // FIXME: Would make more sense to put this in OrgInvoice?
    // @deprecated - use privacyContact instead, since we need name and position for the agreement
    gdpr?: string;
    raw?: any;
    invoice?: OrgInvoice;
    schoolCode?: string; // BRIN or other local code
    privacyContact?: PrivacyContactDetails /** added new instead of `gdpr` since we need more info */;
    brin?: string;
    kvk?: string;
    licenseContactName?: string; // Displayed to members of the school
    licenseContactEmail?: string; // Displayed to members of the school
  }

  export interface OrgInvoice {
    type?: string;
    vatNumber?: string;
    invoiceAttentionOf?: string;
    invoiceAddress?: string;
    invoiceZipcode?: string;
    invoiceCity?: string;
    invoiceCountry?: string;
  }

  export interface License {
    free: boolean;
    amount: number;
    startDate?: Date;
    expireDate?: Date;
    type?: OrganizationLicenseType;
    childLicenseDistribution?: ChildLicenseDistribution;
    pricePerSeat?: number;

    /**
     * @deprecated - Value is 'true' when license type is set and 'false' when it is not set
     */
    isCommercialLicense?: boolean;
    /** @deprecated in favor of type. */
    hasAdminPlusFeatures?: boolean;
  }

  export interface Location {
    id: string;
    name: string;
    schoolId?: string;
    address?: Record<string, string | number>;
  }

  export interface SurfConext {
    enabled: boolean;
    domains: string;
    autoJoin: boolean;
    showChildPicker: boolean;
  }

  export type ChildLicenseDistribution = { id: string; amount: number }[];

  export type OrganizationLicenseType = 'basic' | 'complete' | 'plus' | 'pay-per-use';

  export const defaultLicenseType: OrganizationLicenseType = 'complete';

  export interface ActivityStatus {
    Code: number;
    Status: string;
  }

  export const MEMBERACTIVITY = {
    TIER1: 1,
    TIER2: 2,
    TIER3: 3,
    TIER4: 4,
  };

  export interface OrganizationDataForChildPicker {
    _id: string;
    name: string;
    city?: string;
    address?: string;
  }

  const ACTIVITY_DAYS_AGO_TIER_1 = 30;
  const ACTIVITY_DAYS_AGO_TIER_2 = 90;
  const ACTIVITY_DAYS_AGO_TIER_3 = 180;

  export function getCountryForOrganization(organization: Organization | undefined) {
    return organization?.contact?.country;
  }

  export function organizationForUser(user: MongoUser | undefined, organizations: Organization[]): Organization[] {
    const ids = MongoUser.organizationList(user).map((ol) => ol.id);
    return organizations.filter((o) => ids.includes(o._id));
  }

  export function adminOrganizationForUser(user: MongoUser | undefined, organizations: Organization[]): Organization[] {
    const ids = MongoUser.adminOrganizationList(user).map((ol) => ol.id);
    return organizations.filter((o) => ids.includes(o._id));
  }

  export function accessToProduct(organizations: Organization[], productId: string): Organization[] {
    return accessToOneOfProduct(organizations, [productId]);
  }

  export function productIds(organization: Organization | undefined): string[] {
    return organization?.products?.map((p) => p._id) || [];
  }

  export function productTypes(organization: Organization | undefined): ProductWithSource[] {
    return organization?.products?.map((p) => ({ productId: p._id })) || [];
  }

  export function allProductsInList(organizations: Organization[] = []): string[] {
    return _.uniq(_.flatten(organizations.map((o) => productIds(o))));
  }

  export function accessToOneOfProduct(organizations: Organization[], products: string[]): Organization[] {
    return organizations.filter((o) => o?.products?.find((p) => products.includes(p._id)));
  }

  export function isCommercialLicense(organization: Organization | undefined): boolean {
    return !!organization?.license?.isCommercialLicense;
  }

  export function licenseIsValid(organization: Organization): boolean {
    return !!organization.license && (!organization.license.expireDate || !hasExpired(organization.license.expireDate));
  }

  export function licenseType(organization: Organization | undefined): OrganizationLicenseType | undefined {
    return organization?.license?.type || undefined;
  }

  export function licenseTypeConsideringParent(
    organization: Organization,
    getParent: (parentId: ID) => Organization | undefined
  ): OrganizationLicenseType | undefined {
    const childLicense = licenseType(organization);

    if (organization.parentOrganization) {
      const parentOrg = getParent(organization.parentOrganization);
      const parentLicense = licenseType(parentOrg);
      if (parentLicense && Organization.organizationManagesChildLicenses(parentOrg)) return parentLicense;
    }

    return childLicense;
  }

  export function hasCompleteFeatures(organization: Organization | undefined): boolean {
    const hasFeatures: OrganizationLicenseType[] = ['pay-per-use', 'complete', 'plus'];
    const license = licenseType(organization);
    return (!!organization && license && hasFeatures.includes(license) && licenseIsValid(organization)) || false;
  }

  export function oneHasCompleteFeatures(organizations: Organization[]): boolean {
    return !!organizations.find(hasCompleteFeatures);
  }

  export function hasPlusLicense(organization: Organization | undefined): boolean {
    const hasFeatures: OrganizationLicenseType[] = ['plus'];
    const license = licenseType(organization);
    return (license && hasFeatures.includes(license)) || false;
  }

  export function hasValidCommercialLicense(organization: Organization): boolean {
    if (!organization.license.expireDate) return false;
    return (
      isCommercialLicense(organization) && isBefore(new Date(), stringToDateUnsafe(organization.license.expireDate))
    );
  }

  export function isPublisher(organization?: Organization): boolean {
    return !!organization && licenseIsValid(organization) && !!organization.settings.isPublisher;
  }

  export function oneIsPublisher(organizations: Organization[]): boolean {
    return !!organizations.find(isPublisher);
  }

  export function isSuper(organization: Organization | undefined): boolean {
    return !!organization?.settings.isSuper;
  }

  const PRICE_PER_SEAT_FALLBACK = 67;
  export function getPricePerSeat(organization: Organization): number {
    const { license } = organization;
    const hasPricePerSeat = license?.pricePerSeat !== undefined && isNumber(license?.pricePerSeat);
    return hasPricePerSeat ? license.pricePerSeat! : PRICE_PER_SEAT_FALLBACK;
  }

  export function childOrganizationLicenseAmount(
    parentOrganization: Organization | undefined,
    childId: string
  ): number | undefined {
    return parentOrganization?.license.childLicenseDistribution?.find((license) => license.id === childId)?.amount;
  }

  export function licenseAmount(organization: Organization | undefined): number {
    return organization?.license?.amount || 0;
  }

  export function requestedChildLicensesAreAvailable(
    parentOrganization: Organization | undefined,
    organizationId: string,
    requestedTotalAmount: number
  ): boolean {
    if (
      !parentOrganization ||
      !organizationManagesChildLicenses(parentOrganization) ||
      !parentOrganization.license.amount
    ) {
      return false;
    }

    const distributionsWithoutRequestedChild = (parentOrganization.license.childLicenseDistribution || []).filter(
      ({ id }) => id !== organizationId
    );
    const updated: ChildLicenseDistribution = [
      ...distributionsWithoutRequestedChild,
      { id: organizationId, amount: requestedTotalAmount },
    ];

    return sumChildLicenses(updated) <= parentOrganization.license.amount;
  }

  export function organizationManagesChildLicenses(organization: Organization | undefined): boolean {
    return Boolean(organization && organization.settings.isSuper && organization.settings.manageChildLicenses);
  }

  export function unusedLicensesForParentOrganization(organization: Organization | undefined): number | undefined {
    if (!organization || !organizationManagesChildLicenses(organization)) return;

    return Math.max(0, organization.license.amount - sumChildLicenses(organization.license.childLicenseDistribution));
  }

  function sumChildLicenses(childLicenses: ChildLicenseDistribution | undefined): number {
    if (!childLicenses) return 0;
    return _.sumBy(childLicenses, (license) => license.amount);
  }

  export function anyToOrganization({
    _id,
    slug,
    name,
    settings,
    license,
    creationDate,
    currency,
    ...rest
  }: any): Organization | undefined {
    check(_id, 'string');
    check(slug, 'string');
    check(name, 'string');
    check(creationDate, 'date');
    check(currency, 'string');

    // TODO: Also check internals
    check(settings, 'object');
    check(license, 'object');

    return {
      _id,
      slug,
      name,
      settings,
      license,
      creationDate,
      currency,
      ...rest,
    };
  }

  export function totalAmountOfChildLicenses(organization: Organization): number {
    return sumChildLicenses(organization.license.childLicenseDistribution);
  }

  export function surfConextAllowedForOrganization(emails: string[], surfconext?: SurfConext): boolean {
    if (!surfconext?.enabled) return false;

    const allowedDomains = surfconext.domains.split(',').map(trim);
    const domainsFromEmails = emails.map(getDomainFromEmail);
    return domainsFromEmails.some((domain) => allowedDomains.includes(domain));
  }

  export function getUserActivityStatus(user: MongoUser): number {
    const lastLogin = user.status?.lastLogin;
    const lastLoginDate = lastLogin?.date ? stringToDateUnsafe(lastLogin?.date)?.getTime() : undefined;
    const currentTime = new Date().getTime();

    if (!isUndefined(lastLoginDate)) {
      const daysAgo = Math.round((currentTime - lastLoginDate) / 1000 / 3600 / 24);

      if (daysAgo <= ACTIVITY_DAYS_AGO_TIER_1) return MEMBERACTIVITY.TIER1;
      if (daysAgo <= ACTIVITY_DAYS_AGO_TIER_2) return MEMBERACTIVITY.TIER2;
      if (daysAgo <= ACTIVITY_DAYS_AGO_TIER_3) return MEMBERACTIVITY.TIER3;
    }
    return MEMBERACTIVITY.TIER4;
  }

  export function childOrganizationsForPicker({ _id, name, contact }: Organization) {
    return {
      _id,
      name,
      city: contact?.city,
      address: contact?.address,
    };
  }

  export function invoiceContact(organization: Organization):
    | {
        organizationName?: string;
        attentionOf?: string;
        email?: string;
        address?: string;
        zipcode?: string;
        city?: string;
        country?: string;
      }
    | undefined {
    if (!organization) return;
    const { contact } = organization;

    if (!contact) return;

    const defaultContactDetails = {
      organizationName: organization.name,
      address: contact.address,
      email: contact.email,
      zipcode: contact.zipcode,
      city: contact.city,
      country: contact.country,
    };

    const { invoice } = contact;

    if (!invoice) return defaultContactDetails;

    return {
      organizationName: organization.name,
      attentionOf: invoice?.invoiceAttentionOf || `${contact.firstname} ${contact.lastname}`,
      email: contact.invoiceEmail || contact.email,
      address: invoice?.invoiceAddress,
      zipcode: invoice?.invoiceZipcode,
      city: invoice?.invoiceCity,
      country: invoice?.invoiceCountry,
    };
  }

  export function invoiceContactLenient(organization?: Organization): {
    organizationName: string;
    attentionOf: string;
    email: string;
    address: string;
    zipcode: string;
    city: string;
    country: string;
  } {
    const emptyStrings = {
      organizationName: '',
      attentionOf: '',
      email: '',
      address: '',
      zipcode: '',
      city: '',
      country: '',
    };
    const fromOrganization = organization ? invoiceContact(organization) : emptyStrings;
    return { ...emptyStrings, ...fromOrganization };
  }
}

export type OrganizationOrId = { organizationId: ID } | { organization: Organization };

export interface OrganizationDetailsForMembers {
  _id: string;
  name: string;
  licenseType?: string;
  licenseContactName?: string;
  licenseContactEmail?: string;
  isSchool?: boolean;
  isPublisher?: boolean;
  address?: Organization.Contact['address'];
  city?: Organization.Contact['city'];
  zipcode?: Organization.Contact['zipcode'];
}
