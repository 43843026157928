import classNames from 'classnames';
import _, { range } from 'lodash';
import React, { CSSProperties } from 'react';
import FeedbackStar from './Star';

export interface FeedbackStarProps {
  onChangeValue?(value: number);
  value: number;
  maxValue: number;
  stars: number;
  allowHoverEffect?: true;
  strokeOnlyOnEmpty?: boolean;
  ratingLabel?: string | React.JSX.Element | undefined;
  style?: CSSProperties;
  className?: string;
}

interface State {
  overrideHighlightCount: number | undefined;
}

export default class FeedbackStars extends React.Component<FeedbackStarProps, State> {
  state: State = {
    overrideHighlightCount: undefined,
  };

  handleStarClick = (value: number) => {
    this.props.onChangeValue && this.props.onChangeValue(value);
  };

  resetHoverState = () => {
    this.setState({
      overrideHighlightCount: undefined,
    });
  };

  handleStarHover = (value: number) => {
    if (!this.props.onChangeValue) {
      return;
    }

    this.setState({
      overrideHighlightCount: value,
    });
  };

  render() {
    const { className } = this.props;
    const highlightCount = !_.isNil(this.state.overrideHighlightCount)
      ? this.state.overrideHighlightCount
      : this.props.value;
    const label = !_.isEmpty(this.props.ratingLabel) ? (
      <div className="feedback-bar__label">{this.props.ratingLabel}</div>
    ) : (
      false
    );

    return (
      <div
        className={classNames('feedback_bar', className)}
        onMouseLeave={this.resetHoverState}
        style={this.props.style}
      >
        {range(this.props.stars).map((n) => {
          const value = ((n + 1) / this.props.stars) * this.props.maxValue;
          const filled = value <= highlightCount;
          return (
            <FeedbackStar
              outlineOnly={Boolean(!filled && this.props.strokeOnlyOnEmpty)}
              value={value}
              filled={filled}
              key={n}
              onClick={this.handleStarClick}
              onHover={this.handleStarHover}
            ></FeedbackStar>
          );
        })}
        {label}
      </div>
    );
  }
}
