import React from 'react';
import { assertNever } from '../../../../utils/TypescriptUtils';
import { useBEM } from '../../../utils/hooks';
import CloseCrossIcon from '../../svgIcons/CloseCrossIcon';
import './InputSubtext.less';

export type SubtextVariant = 'success' | 'error' | 'neutral' | 'disabled';

export interface InputSubtextProps {
  subtext: string;
  subtextVariant: SubtextVariant;
}

export const InputSubtext = ({ subtext, subtextVariant }: InputSubtextProps): React.JSX.Element => {
  const icon = () => {
    switch (subtextVariant) {
      case 'neutral':
        return;
      case 'error':
        return <CloseCrossIcon color="#EC484D" width={14} height={14} />;
      case 'success':
        return 'P';
      case 'disabled':
        return 'P';
      default:
        assertNever(subtextVariant, 'Unhandled variant found');
    }
  };

  const bemClasses = useBEM('input');
  return (
    <>
      {subtext && (
        <span className={bemClasses({ element: 'error' })}>
          {icon()}
          <p>{subtext}</p>
        </span>
      )}
    </>
  );
};
