import { AppError } from '@lessonup/utils';
import React from 'react';
import { LanguageSingleton } from '../../../shared-core/domain';
import { RegistrationStep } from '../../../shared-core/services/app/searchRoutes';
import { assertNever } from '../../../shared-core/utils';
import { DisplayGiftPageView } from './DisplayGiftMessage/DisplayGiftPageView';
import { RegistrationCredentialsServiceWrapper } from './NewRegistrationPages/RegistrationCredentialsServiceWrapper';
import { RegistrationEmailVerificationSentServiceWrapper } from './NewRegistrationPages/RegistrationEmailVerificationSentServiceWrapper';
import { RegistrationPasswordServiceWrapper } from './NewRegistrationPages/RegistrationPasswordServiceWrapper';
import { RegistrationVerificationLinkExpiredServiceWrapper } from './NewRegistrationPages/RegistrationVerificationLinkExpiredServiceWrapper';
import ReferralWelcome from './RegistrationPages/ReferralWelcome';
import { RegistrationDemoLessonPage } from './RegistrationPages/RegistrationDemoLesson/RegistrationDemoLessonPage';
import { SchoolPickerPage } from './RegistrationPages/RegistrationFlowSchoolPicker/SchoolPickerPage';
import { RegistrationPickChildPage } from './RegistrationPages/RegistrationPickChildPage/RegistrationPickChildPage';
import RegistrationSelectApplication from './RegistrationPages/RegistrationSelectApplication';
import { RegistrationSuggestionsPage } from './RegistrationPages/RegistrationSuggestions/RegistrationSuggestionsPage';
import RegistrationTerms from './RegistrationPages/RegistrationTerms';
import { RegistrationFormProps } from './types';
import { isClientOnlyRegistrationStep } from './utils/isClientOnlyRegistrationStep';

export type RegistrationPageViewProps = RegistrationFormProps & {
  step: RegistrationStep;
  onAcceptTerms: () => void;
  acceptEmails: () => void;
  organizationId?: string;
  referralToken?: string;
  credentialEmail?: string;
};

export const RegistrationPageView: React.FC<RegistrationPageViewProps> = (props) => {
  function renderFormStep(): React.JSX.Element | null {
    const formStepProps: RegistrationFormProps = {
      next: props.next,
      goToStep: props.goToStep,
    };

    if (!isClientOnlyRegistrationStep(props.step)) {
      throw new AppError('invalid-params', 'Client-side method called with server side registration step.');
    }

    switch (props.step) {
      case 'select':
        return <RegistrationSelectApplication {...formStepProps} />;
      case 'credentials':
        return <RegistrationCredentialsServiceWrapper {...formStepProps} referralToken={props.referralToken} />;
      case 'verification-sent':
        return <RegistrationEmailVerificationSentServiceWrapper {...formStepProps} />;
      case 'password':
        return <RegistrationPasswordServiceWrapper {...formStepProps} />;
      case 'terms':
        return (
          <RegistrationTerms
            {...formStepProps}
            language={LanguageSingleton.get()}
            onAcceptTerms={props.onAcceptTerms}
            acceptEmails={props.acceptEmails}
            referralToken={props.referralToken}
          />
        );
      case 'suggestions':
        return <RegistrationSuggestionsPage {...formStepProps} />;
      case 'organization':
        return <SchoolPickerPage {...formStepProps} />;
      case 'demo-lesson':
        return <RegistrationDemoLessonPage {...formStepProps} />;
      case 'pick-child':
        return <RegistrationPickChildPage organizationId={props.organizationId} {...formStepProps} />;
      case 'pick-child-school-page':
        return (
          <RegistrationPickChildPage organizationId={props.organizationId} source="schools-page" {...formStepProps} />
        );
      case 'referral':
        const { referralToken } = props;
        if (!referralToken) {
          props.goToStep('select');
          return null;
        }
        return <ReferralWelcome {...props} referralToken={referralToken} />;
      case 'registration-gift':
        return <DisplayGiftPageView />;
      case 'verification-expired':
        return <RegistrationVerificationLinkExpiredServiceWrapper {...formStepProps} />;
      default:
        assertNever(props.step, 'Unknown registration step');
    }
  }

  return renderFormStep();
};
