import { capitalize, isString } from 'lodash';
import qs from 'qs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useParams } from 'react-router';
import { useAppServices } from '../../components/appServices/AppServicesContext';
import RenewMagicLinkForm from '../../components/renewMagicLinkForm/renewMagicLinkForm';
import GrayPageLayout, {
  GrayPageLayoutTitle,
  GrayPageSection,
  GrayPageSubtitle,
} from '../GrayPageLayout/GrayPageLayout';

const TRANSLATION_NS = 'renewMagicLink';
type Props = RouteComponentProps;

const RenewMagicLinkPage: React.FC<Props> = (props) => {
  const { language } = useParams<{ language: string }>();
  const { location } = props;
  const { email } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [completed, setCompleted] = useState(false);
  const { t } = useTranslation(TRANSLATION_NS);
  const { user } = useAppServices();

  function handleSubmit(email: string): Promise<void> {
    const emailVariant = 'genericLoginLink';
    return user.requestMagicLink(email, language, emailVariant).then(() => setCompleted(true));
  }

  const parsedEmailFromQuery = isString(email) ? email : JSON.stringify(email);

  const Form = () => {
    return (
      <>
        <GrayPageLayoutTitle>{capitalize(t('pageTitle'))}</GrayPageLayoutTitle>
        <GrayPageSubtitle>{t('pageSubtitle')}</GrayPageSubtitle>
        <div className="login-options forgot-password">
          <RenewMagicLinkForm email={parsedEmailFromQuery} onSubmit={(email: string) => handleSubmit(email)} />
        </div>
      </>
    );
  };

  const Confirmation = () => {
    return (
      <>
        <GrayPageLayoutTitle>{capitalize(t('confirmationTitle'))}</GrayPageLayoutTitle>
        <GrayPageSubtitle>{t('confirmationDescription')}</GrayPageSubtitle>
      </>
    );
  };

  return (
    <GrayPageLayout>
      <GrayPageSection position="top" className="spread-content">
        {completed ? <Confirmation /> : <Form />}
      </GrayPageSection>
    </GrayPageLayout>
  );
};

export default RenewMagicLinkPage;
