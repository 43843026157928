import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { LessonPlan, Product, ProductWithSource } from '../../../shared-core/domain';
import { FolderRenderParams } from '../../../shared-core/domain/newExplorers';
import { HandleClickedLocked } from '../../pages/channel/subpage/ChannelFolderPage';
import './css/LessonPlanFolder.less';
import FolderInfo from './FolderInfo';
import FolderVisual from './FolderVisual';

type FolderParams = FolderRenderParams;
type Props = {
  folder: FolderParams;
  url?: string;
  userProducts?: ProductWithSource[];
  handleClickLocked?: HandleClickedLocked;
  showSeriesButtons?: boolean;
  parentProducts?: string[];
  child?: LessonPlan.Children.Folder;
  children?: React.ReactNode;
};

const LessonPlanFolder: React.FC<Props> = ({
  folder,
  userProducts,
  handleClickLocked,
  children,
  showSeriesButtons,
  parentProducts,
  url,
}) => {
  const userProductIds = userProducts?.map((p) => p.productId);
  const folderAccess = Product.canAccess((folder?.products || []).concat(parentProducts || []), userProductIds);
  const history = useHistory();

  if (!folder) {
    console.warn('LessonPlanFolder: Folder cannot be found');
    return null;
  }

  const handleClickLockedFolder = () => {
    handleClickLocked && handleClickLocked(folder);
  };

  const handleClickNavigate = (folder: FolderParams) => {
    url ? history.push(url) : folder.url && history.push(folder.url);
  };

  if (!folderAccess) {
    return (
      <div onClick={handleClickLockedFolder} className="locked-folder">
        <LessonPlanFolderView folder={folder} showSeriesButtons={!!showSeriesButtons} />
      </div>
    );
  }

  if (showSeriesButtons) {
    return (
      <LessonPlanFolderView folder={folder} showSeriesButtons={true} onClick={handleClickNavigate}>
        {children}
      </LessonPlanFolderView>
    );
  }

  // normal folder
  return (
    <Link className="wrapper-link" to={url ? url : folder?.url ? folder.url : ''}>
      <LessonPlanFolderView folder={folder} showSeriesButtons={false} />
    </Link>
  );
};

type LessonPlanFolderViewProps = {
  folder: FolderParams;
  showSeriesButtons: boolean;
  onClick?: (folder: FolderParams) => void;
  children?: React.ReactNode;
};

const LessonPlanFolderView: React.FC<LessonPlanFolderViewProps> = ({
  folder,
  children,
  showSeriesButtons,
  onClick,
}) => {
  const handleClick = () => {
    onClick && onClick(folder);
  };

  return (
    <div
      className={classNames('lesson-folder lesson-plan-folder', {
        'show-series-buttons': showSeriesButtons,
        'no-series-buttons': !showSeriesButtons,
      })}
      onClick={handleClick}
    >
      <FolderVisual folder={folder} />
      <FolderInfo name={folder.name} description={folder.description}>
        {children}
      </FolderInfo>
    </div>
  );
};

export default LessonPlanFolder;
