import classNames from 'classnames';
import React from 'react';
import { lessonSearchRoute } from '../../../shared-core/services/app/searchRoutes';
import RocketSvg from '../../../shared-core/ui/components/logos/RocketSvg';
import AnchorOrLink from '../AnchorLink/AnchorOrLink';
import './rocketLogo.less';

export interface LogoProps {
  className?: string;
  isSignedIn: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export default function Logo({ className, isSignedIn, onClick }: LogoProps) {
  return (
    <AnchorOrLink
      url={lessonSearchRoute()}
      isAnchor={isSignedIn}
      className={classNames('rocket-logo', className)}
      onClick={onClick}
    >
      <RocketSvg height="30" width="30" />
      <span>LessonUp</span>
    </AnchorOrLink>
  );
}
