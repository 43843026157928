import { RefObject, useEffect } from 'react';

const useOnClickOutside = (
  ref: RefObject<HTMLElement | null>,
  handleClickOutside: () => void,
  exceptionCssClasses?: string[]
) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!ref.current || ref.current.contains(e.target as Node)) return;
      if (exceptionCssClasses?.length) {
        const inException = exceptionCssClasses.some((className) => {
          return Array.from(document.getElementsByClassName(className)).some((el) => el.contains(e.target as Node));
        });
        if (inException) return;
      }

      handleClickOutside();
    };

    document.addEventListener('click', handleClick, { passive: true });
    document.addEventListener('touchstart', handleClick, { passive: true });

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
    // turned array into string to prevent unnessasairy event listener creation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, handleClickOutside, exceptionCssClasses?.join(',')]);
};

export default useOnClickOutside;
