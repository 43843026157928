import { AssignmentType } from '@lessonup/teaching-core';
import { Button, Headline, IconArrowLeft, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExplorerLessonContent } from '../../../../shared-core/domain/newExplorers';
import PreviewThumbnail from '../../../../shared-core/ui/components/lesson/previewThumbnail/PreviewThumbnail';

type Props = {
  content: ExplorerLessonContent[];
  submitForm: ({ assignmentType, lessonId }: { assignmentType: AssignmentType; lessonId: string }) => Promise<void>;
  onCancel: () => void;
};

const CanvasConfirmSelection = (props: Props) => {
  const { t } = useTranslation('canvasConfirm');
  const lesson = props.content[0].content;

  return (
    <ConfirmContainer>
      <TopBar>
        <SpaceBetween direction="y" spacing={spacing.size8}>
          <div>
            <Button
              size="small"
              buttonType="neutral"
              showStroke
              onClick={() => props.onCancel()}
              iconStart={<IconArrowLeft />}
            >
              {t('buttonBack')}
            </Button>
          </div>
          <Headline size="medium">{lesson.name}</Headline>
        </SpaceBetween>
      </TopBar>
      {lesson.thumbnail?.url && <PreviewThumbnail lesson={lesson} extraElement={false} />}
      <ButtonWrapper>
        <SpaceBetween alignItems="center" direction="x" spacing={spacing.size2} justifyContent="space-around">
          <Button
            size="small"
            buttonType="secondary"
            onClick={() => props.submitForm({ assignmentType: 'homework', lessonId: props.content[0].content._id })}
          >
            {t('buttonHomework')}
          </Button>
        </SpaceBetween>
      </ButtonWrapper>
    </ConfirmContainer>
  );
};

export default CanvasConfirmSelection;

const ConfirmContainer = styled.div`
  padding: ${spacing.size12};
  max-width: 400px;
  margin: auto;
`;

const ButtonWrapper = styled.div`
  margin-top: ${spacing.size16};
  align-items: center;
`;

const TopBar = styled.div`
  margin-top: ${spacing.size8};
  margin-bottom: ${spacing.size8};
`;
