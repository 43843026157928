import React from 'react';
import { Link } from 'react-router-dom';
import { Explanation } from '../../../shared-core/domain';
import { searchClusterRoute } from '../../../shared-core/services/app/searchRoutes';
import { Modal } from '../../../shared-core/ui/components/modals/Modal';
import './explanationBox.less';

interface Props {
  explanation: Explanation;
  collapseCount?: number | undefined;
  id: string;
}

interface State {
  isOpen: boolean;
}

export default class ExplanationBox extends React.Component<Props, State> {
  state = { isOpen: false };

  handleOpenClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.setState({ isOpen: true });
  };

  handleModalClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { explanation, collapseCount, id } = this.props;

    return (
      <div className="search-explanation-box">
        {collapseCount != null && (
          <Link to={searchClusterRoute(id)} onClick={(e) => e.stopPropagation()}>
            collapse: {collapseCount}{' '}
          </Link>
        )}
        <a onClick={this.handleOpenClick} className="search-explanation-box__link">
          Score {explanation.value}
        </a>
        <Modal title="Explanation" isOpen={this.state.isOpen} onRequestClose={this.handleModalClose}>
          <ExplanationItem explanation={explanation} />
        </Modal>
      </div>
    );
  }
}

interface ExplanationItemProps {
  explanation: Explanation;
}

const ExplanationItem = (props: ExplanationItemProps) => {
  return (
    <div className="search-explanation-item">
      <div>
        <b>{props.explanation.value}</b> {props.explanation.description}
      </div>
      <ol>
        {props.explanation.details.map((d, i) => (
          <li key={i}>
            <ExplanationItem explanation={d} />
          </li>
        ))}
      </ol>
    </div>
  );
};
