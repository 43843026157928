import { tracker } from '@lessonup/analytics';
import { logger } from '@lessonup/client-integration';
import { ID } from '@lessonup/teaching-core';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';
import { showDefaultErrorNotification } from '../../../shared-core/client/utils/modals';
import { OrganizationId } from '../../../shared-core/domain';
import { teacherHome } from '../../../shared-core/services/app/teacherRoutes';
import Button from '../../../shared-core/ui/components/Button';
import { Card } from '../../../shared-core/ui/components/Cards/Card';
import LinkButton from '../../../shared-core/ui/components/links/LinkButton';
import { useBEM } from '../../../shared-core/ui/utils/hooks';
import useToggle from '../../../shared-core/ui/utils/useToggle';
import { useAppServices } from '../../components/appServices/AppServicesContext';
import { RegistrationCheckboxes } from '../../components/RegistrationCheckboxes/RegistrationCheckboxes';
import { loggedInUser, organizationsForPicker } from '../../redux/selectors';
import { RedirectService } from '../../services/RedirectService';
import { withQueryString } from '../../utils/routeUtils/routeUtils';
import GrayPageLayout, { GrayPageSection } from '../GrayPageLayout/GrayPageLayout';
import { CardContentForOrganization } from './CardContentForOrganization/CardContentForOrganization';
import './ChildPickerPage.less';

export const CHILD_PICKER_PAGE_TRANSLATION_NAMESPACE = 'childPickerPage';
export type ResponseStatus = 'error' | 'success';
interface OrganizationRequestResponse {
  organizationId: string;
  resultStatus: ResponseStatus;
}

export const ChildPickerPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation(CHILD_PICKER_PAGE_TRANSLATION_NAMESPACE);
  const { modals, user } = useAppServices();
  const organizations = useSelector(organizationsForPicker());
  const bemClasses = useBEM('ChildPickerPage');
  const [acceptTerms, setAcceptTerms] = useToggle(false);
  const [acceptEmails, setAcceptEmails] = useToggle(false);
  const userState = useSelector(loggedInUser());
  const location = useLocation();
  const [responseForOrganizations, setResponseForOrganizations] = useState<OrganizationRequestResponse[]>([]);

  function getSourceFromUrl() {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const source = query['source'];
    return source;
  }

  useEffect(() => {
    RedirectService.setRedirectUrlFromQueryString(location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestToJoin = async (organizationId: ID) => {
    try {
      await Promise.all([user.sendOrganizationJoinRequest(organizationId), acceptTermsAndEmails()]);
      handleRequestResponse({ organizationId, resultStatus: 'success' });
      tracker.events.teacherRequestToJoinOrganizationSent({
        organizationId,
        requestToJoinMethod: 'email_domain_suggestion',
      });
    } catch (error) {
      logger.error(error);
      handleRequestResponse({ organizationId, resultStatus: 'error' });
    }
  };

  async function acceptTermsAndEmails() {
    try {
      if (!acceptTerms) return;
      const termsPromise = user.onAcceptTerms();
      const emailPromise = acceptEmails ? user.setAcceptEmails() : undefined;
      await Promise.all([termsPromise, emailPromise]);
    } catch (error) {
      logger.error(error);
      showDefaultErrorNotification();
    }
  }

  const handleRequestResponse = ({
    organizationId,
    resultStatus,
  }: {
    organizationId: OrganizationId;
    resultStatus: ResponseStatus;
  }) => {
    const requestResponse: OrganizationRequestResponse = {
      organizationId,
      resultStatus,
    };
    setResponseForOrganizations([requestResponse, ...responseForOrganizations]);
  };

  const goToMyLessons = () => {
    const url = RedirectService.getRedirectUrl() ?? teacherHome();
    window.open(withQueryString(url, '_self'));
  };

  const requestResultStatusByOrganizationId = (organizationId: ID) => {
    return responseForOrganizations.find((response) => response.organizationId === organizationId)?.resultStatus;
  };

  const handleSkipButton = async () => {
    if (!acceptTerms) {
      return;
    }

    await acceptTermsAndEmails();

    const skipPickingChildOrganization = await modals.open('confirm', {
      title: t('modalTitle'),
      children: <p>{t('modalBody')}</p>,
    });
    if (skipPickingChildOrganization) {
      goToMyLessons();
    }
  };

  const disableContinueButton = !responseForOrganizations.length || (!acceptTerms && !userState?.acceptedTerms);

  return (
    <GrayPageLayout className="child-picker-page">
      <GrayPageSection position="top" className="spread-content">
        <h1 className={bemClasses({ element: 'title' })}>{t('title')}</h1>
        <div className={bemClasses({ element: 'description' })}>
          <p>{t('description')}</p>
          {getSourceFromUrl() === 'lti' ? <p>{t('descriptionLTI')}</p> : null}
        </div>

        <div className={bemClasses({ element: 'cards-container' })}>
          {organizations.map((org, i) => {
            const requestResult = requestResultStatusByOrganizationId(org._id);
            return (
              <Card key={(org._id, +i)}>
                <CardContentForOrganization
                  id={org._id}
                  name={org.name}
                  requestResult={requestResult}
                  onRequestToJoin={requestToJoin}
                  cardButtonTheme={'secondary'}
                />
              </Card>
            );
          })}
        </div>
        {!userState?.acceptedTerms && (
          <RegistrationCheckboxes
            emailAccepted={acceptEmails}
            termsAccepted={acceptTerms}
            setTermsAccepted={setAcceptTerms}
            setEmailAccepted={setAcceptEmails}
            className={bemClasses({ element: 'checkboxes' })}
          />
        )}
      </GrayPageSection>
      <GrayPageSection position="bottom">
        <Button
          height="medium"
          theme="secondary"
          version="modern"
          disabled={disableContinueButton}
          className={bemClasses({ element: 'create-account-button' })}
          onClick={goToMyLessons}
        >
          {t('continue')}
        </Button>
        {!responseForOrganizations.length && (
          <LinkButton
            bgColor="transparent"
            className={bemClasses({ element: 'skip-button', modifier: !acceptTerms && 'disabled' })}
            onClick={handleSkipButton}
            text={t('skipThisStep')}
            underline
            big={false}
            height="small"
          />
        )}
      </GrayPageSection>
    </GrayPageLayout>
  );
};
