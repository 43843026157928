import { LoggerTransport, SentryEditorScope, SentryPlayerScope, TraceCategory } from '@lessonup/client-integration';
import { ID } from '@lessonup/teaching-core';
import { AppError, AppErrorCode, LogDetails } from '@lessonup/utils';
import { addBreadcrumb, captureException, captureMessage, init, setContext, withScope } from '@sentry/browser';
import { CaptureContext, ErrorEvent, EventHint } from '@sentry/core';
import { isNil, isObject, isString, mapValues, omitBy } from 'lodash';
import { EnvConfig } from '../../../../services/app';
export const errorsToInfoCodes: AppErrorCode[] = [
  'not-authenticated',
  'vimeo:video-not-embeddable',
  'vimeo:video-not-found',
];
const ignoreErrors: (string | RegExp)[] = [
  'Non-Error exception captured',
  'Non-Error promise rejection captured',
  // Login popup from Google is sometimes blocked by intergrated browsers from apps like Snapchat.
  // Google shows its own error message, so we don't need to log this.
  /^The login popup was blocked by the browser$/,
];

export class SentryTransport implements LoggerTransport {
  private get debugMode() {
    return (this.envConfig.runsOnLocalhost || this.envConfig.isDev) && window.localStorage.getItem('debug') === 'true';
  }

  public constructor(
    private readonly envConfig: EnvConfig,
    private readonly sentry: { dsn?: string; disabled?: boolean }
  ) {
    if (this.envConfig.runsOnLocalhost && this.sentry.disabled) {
      // eslint-disable-next-line no-console
      console.info('Detected Local Env with Sentry disabled. ');
    } else {
      this.initializeSentry();
    }
  }

  public info(message: string | Error | AppError, details?: LogDetails): void {
    if (isString(message)) {
      captureMessage(...stringToCaptureParams(message, details));
      return;
    }

    if (message instanceof AppError) {
      captureMessage(...appErrorToCaptureMessageParams(message, details));
      return;
    }

    captureMessage(...errorToCaptureMessageParams(message, details));
  }

  public warn(message: string | Error | AppError, details?: LogDetails): void {
    return this.info(message, details);
  }

  public error(message: string | Error | AppError, details?: LogDetails): void {
    if (shouldBeInfo(message)) return this.info(message, details);

    if (isString(message)) {
      captureException(...stringToCaptureParams(message, details));
      return;
    }

    if (message instanceof AppError) {
      captureException(...appErrorToCaptureParams(message, details));
      return;
    }

    captureException(...errorToCaptureErrorParams(message, details));
  }

  public setUserId(userId: ID | undefined): void {
    withScope((scope) => {
      if (!userId) return scope.setUser({});
      scope.setUser({ id: userId });
    });
  }

  public setEditorScope(sentryEditorScope: SentryEditorScope | null): void {
    setContext('editor', sentryEditorScope as Record<string, any>);
  }

  public setPlayerScope(sentryPlayerScope: SentryPlayerScope | null): void {
    setContext('player', sentryPlayerScope as Record<string, any>);
  }

  public trace(category: TraceCategory, message: string): void {
    addBreadcrumb({
      category,
      message,
      level: 'info',
    });
  }

  private initializeSentry(): void {
    const release = this.envConfig.versionTagName;
    const beforeSend = beforeSendFactory(this.debugMode);

    init({
      dsn: this.sentry.dsn,
      release,
      maxBreadcrumbs: 100,
      ignoreErrors,
      beforeSend,
      environment: this.envConfig.env,
    });

    withScope((scope) => {
      scope.setTag('mode', this.envConfig.env);
      scope.setTag('app', this.envConfig.app);
      scope.setTag('versionTag', release);
    });
  }
}

function shouldBeInfo(error: string | Error | AppError): boolean {
  if (!AppError.isAppError(error)) return false;
  const { code } = error;
  return errorsToInfoCodes.includes(code);
}

function beforeSendFactory(isLocal?: boolean): (event: ErrorEvent, hint: EventHint) => ErrorEvent | null {
  return function (event) {
    if (stacktraceContainsExternalScript(event)) {
      return null;
    }
    const stableEvent = removeRandomPartFromExceptionMessage(event);
    // eslint-disable-next-line no-console
    if (isLocal) console.debug('To Sentry: ', stableEvent);
    return stableEvent;
  };
}

function stringToCaptureParams(message: string, details?: LogDetails): [string, CaptureContext] {
  return [
    message,
    {
      tags: logDetailsToTags(details),
      extra: { ...details },
    },
  ];
}

function errorToCaptureErrorParams(message: Error, details?: LogDetails): [Error, CaptureContext] {
  return [
    message,
    {
      tags: logDetailsToTags(details),
      extra: { ...details, error: message },
    },
  ];
}

function errorToCaptureMessageParams(message: Error, details?: LogDetails): [string, CaptureContext] {
  return [
    message.message,
    {
      tags: logDetailsToTags(details),
      extra: { ...details, error: message },
    },
  ];
}

function appErrorToCaptureParams(message: AppError, details?: LogDetails): [Error, CaptureContext] {
  const { code, details: errorDetails } = message;
  return [
    message,
    {
      tags: {
        code,
        ...logDetailsToTags({ ...errorDetails, ...details }),
      },
      extra: { error: message, ...details, ...errorDetails },
    },
  ];
}

function appErrorToCaptureMessageParams(message: AppError, details?: LogDetails): [string, CaptureContext] {
  const { code, details: errorDetails } = message;
  return [
    message.message,
    {
      tags: {
        code,
        ...logDetailsToTags({ ...errorDetails, ...details }),
      },
      extra: { error: message, ...details, ...errorDetails },
    },
  ];
}

function logDetailsToTags(logDetails: LogDetails = {}): {
  [key: string]: string | undefined;
} {
  const stringDetails = mapValues(logDetails, stringOrUndefined);
  const stringTags = isObject(logDetails.tags) ? mapValues(logDetails.tags, stringOrUndefined) : {};
  const validTags = omitBy({ ...stringDetails, ...stringTags }, isNil);

  return validTags;
}

function stringOrUndefined(value: unknown): string | undefined {
  return isString(value) ? value : undefined;
}

export const urlWithLessonupDomain =
  /^(https?:\/\/(?:[a-zA-Z0-9-]+\.)?(lessonup\.com|lessonup\.dev|lessonup\.app|localhost))/i;

function stacktraceContainsExternalScript(event: ErrorEvent): boolean {
  return (
    event.exception?.values?.some((value) =>
      value.stacktrace?.frames?.some((frame) => {
        return !frame.filename?.match(urlWithLessonupDomain);
      })
    ) || false
  );
}
/**  
https://lessonup.atlassian.net/browse/DEFECT-1793  
@description Group similar errors with different error messages to prevent bloat in #sentry-alerts channel.  
*/
function removeRandomPartFromExceptionMessage(event: ErrorEvent): ErrorEvent {
  const values = event.exception?.values?.map((item) => {
    // this error started to appear, but can't reproduce it locally, so grouping it for now
    if (item.value?.match(/^_jp\..* is not a function/)) {
      return { ...item, value: '_jp.* is not a function(altered in SentryTransport.ts)' };
    }
    return item;
  });

  return {
    ...event,
    exception: {
      ...event.exception,
      values,
    },
  };
}
