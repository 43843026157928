import {
  AnyAnswer,
  AnyAssignmentPin,
  AssignmentPin,
  Component,
  LessonPin,
  Pin,
  PinType,
} from '@lessonup/teaching-core';
import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import { PinViewUtils } from '../../../utils/PinViewUtils';
import DragQuestionView from '../content-types/dragQuestion/DragQuestionView';
import LinkView from '../content-types/link/LinkView';
import MapView from '../content-types/map/MapView';
import OpenQuestionView from '../content-types/openQuestion/OpenQuestionView';
import PollView from '../content-types/poll/PollView';
import QuizView from '../content-types/quiz/QuizView';
import SlideView from '../content-types/slide/SlideView';
import VideoView from '../content-types/video/VideoView';
import WordwebView from '../content-types/wordweb/WordwebView';
import Fact from '../fact/Fact';
import ArrowDownRight from '../svgIcons/ArrowDownRight';
import './PinView.less';

const contentTypeMap: Record<PinType, React.ComponentClass<MapPinProps> | React.FunctionComponent<MapPinProps>> = {
  dragQuestion: DragQuestionView,
  link: LinkView,
  map: MapView,
  openQuestion: OpenQuestionView,
  question: QuizView,
  slide: SlideView,
  video: VideoView,
  wordweb: WordwebView,
  poll: PollView,
};

export interface MapPinProps<T extends AssignmentPin = AssignmentPin> {
  pin: T;
  correctAnswers: AnyAnswer[] | undefined;
  isThumb?: boolean;
  useThumbnailForComponentType?: Component.Type[];
}

type LessonPinOrAssignmentPin = { lessonPin: LessonPin } | { assignmentPin: AssignmentPin };
const mapPinToContentType = (
  lessonPinOrAssignmentPin: LessonPinOrAssignmentPin,
  isThumb?: boolean,
  useThumbnailForComponentType?: Component.Type[]
): React.ReactElement<MapPinProps, any> | undefined => {
  const lessonPin: LessonPin | undefined = lessonPinOrAssignmentPin['lessonPin'];
  const assignmentPin: AnyAssignmentPin | undefined = lessonPinOrAssignmentPin['assignmentPin'];

  const pin = lessonPin || assignmentPin;

  if (!pin) {
    throw new Error('Pin not found');
  }

  const type = PinViewUtils.pinTypeOrVideoSource(pin, isThumb);
  const component = contentTypeMap[type];

  if (!component || typeof component === 'string') return;

  const finalPin =
    assignmentPin || AssignmentPin.assignmentPinFromLessonPin(lessonPin!, false, useThumbnailForComponentType);
  // TODO needs a fix
  const correctAnswers = (
    lessonPin?.item.type === 'question' ? get(lessonPin, 'item.custom.correctAnswer') : undefined
  ) as any;
  return React.createElement(component, { pin: finalPin, correctAnswers, isThumb });
};

export type PinProps = PinPropsWithLessonPin | PinPropsWithAssignmentPin;

export interface PinPropsWithLessonPin extends BasePinProps {
  pin: LessonPin;
}

export interface PinPropsWithAssignmentPin extends BasePinProps {
  assignmentPin: AnyAssignmentPin;
}

interface BasePinProps {
  isThumb?: boolean;
  useThumbnailForComponentType?: Component.Type[];
  hideFact?: boolean;
  fontSize?: string | undefined;
  className?: string;
  pinHeading?: React.JSX.Element;
  contextMenu?: () => React.ReactElement;
  videoPin?: LessonPin;
}

export const PinView = React.memo<PinProps>((props) => {
  const pin = 'pin' in props ? { ...props.pin } : { ...props.assignmentPin };
  const videoPin = props.videoPin;
  const pinFact = PinViewUtils.labelForPinType(pin.item.type);
  const pinVideoViewClass = videoPin ? 'pin-video-view' : undefined;
  const lessonPinOrAssignmentPin = 'pin' in props ? { lessonPin: props.pin } : { assignmentPin: props.assignmentPin };
  return (
    <div
      className={classNames('pin-view', props.className, pinVideoViewClass, { thumbPreview: !!props.isThumb })}
      style={{ fontSize: props.fontSize }}
    >
      {videoPin ? (
        <VideoItem
          lessonPin={pin}
          videoPin={videoPin}
          pinItem={mapPinToContentType(lessonPinOrAssignmentPin, props.isThumb, props.useThumbnailForComponentType)}
        />
      ) : (
        mapPinToContentType(lessonPinOrAssignmentPin, props.isThumb, props.useThumbnailForComponentType)
      )}
      {props.isThumb && pinFact && !props.hideFact && <Fact factTitle={pinFact} />}
      {props.pinHeading}
      {props.contextMenu && props.contextMenu()}
    </div>
  );
});

export default PinView;

interface VideoItemProps {
  pinItem: React.ReactElement<MapPinProps, any> | undefined;
  lessonPin: Pin;
  videoPin: LessonPin;
}

const VideoItem: React.FC<VideoItemProps> = ({ pinItem, lessonPin, videoPin }) => {
  const videoPinData = videoPin.item.videoItems
    ? videoPin.item.videoItems.find((pin) => lessonPin._id === pin.pinId)
    : undefined;
  const startTime = videoPinData ? formatVideoTime(videoPinData.time) : undefined;
  const endTime = videoPinData && videoPinData.end ? formatVideoTime(videoPinData.end) : undefined;

  const thumbStyle = {
    backgroundImage: `url(${videoPin.item.image})`,
  };

  return (
    <div className="video-item">
      <div className="video-slide-thumb" style={thumbStyle}>
        {endTime ? (
          <div className="video-slide-time has-end">
            {startTime}-{endTime}
          </div>
        ) : (
          <div className="video-slide-time">{startTime}</div>
        )}
      </div>
      <ArrowDownRight />
      {pinItem}
    </div>
  );
};

const formatVideoTime = (time: number) => {
  const timeWithHours = new Date(time * 1000).toISOString().substr(12, 7);
  return timeWithHours.substr(0, 1) === '0' ? timeWithHours.substr(2, 5) : timeWithHours;
};
