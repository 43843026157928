import { Lesson, LessonPin, PinType } from '@lessonup/teaching-core';
import { Identifiable } from '@lessonup/utils';
import _ from 'lodash';
import { Omit } from '../../../utils';
import { Channel } from '../../channels';
import { Curriculum } from '../../objectives';
import { LessonStats } from '../../stats';
import { MongoUser } from '../../user';
import { SearchResultItem } from '../SearchResponse';
import { ClusterData } from './ClusterData';
import { CurriculumData } from './CurriculaData';
import { FacetMetaData } from './FacetMetaData';
import { IndexedChannelInfo } from './IndexedChannelInfo';
import { IndexedContent } from './IndexedContent';
import { IndexedPin } from './IndexedPin';

export interface IndexedLessonBase extends Identifiable {
  lesson: Lesson & { channelId?: Channel['_id'] };
  firstPin: LessonPin;
  indexedContent: IndexedContent;
  pinHashes: string[];
}

export interface IndexedLesson extends IndexedLessonBase {
  docType: 'lesson';
  statistics: LessonStats;
  curricula: CurriculumData | undefined;
  channel: IndexedChannelInfo | undefined;
  hash: string;
  metaData: FacetMetaData;
  cluster: ClusterData;
  contentTypes: PinType[];
}

export namespace IndexedLesson {
  export function base(
    lesson: Lesson,
    indexedPins: IndexedPin[],
    lessonChannelId?: Channel['_id']
  ): IndexedLessonBase | undefined {
    const firstPin = LessonPin.firstPin(indexedPins.map((indexedPin) => indexedPin.pin));

    if (!firstPin) {
      return undefined;
    }

    const indexedContent = IndexedContent.flatten(indexedPins.map((p) => p.indexedContent));

    return {
      _id: lesson._id,
      lesson: { ...lesson, channelId: lessonChannelId },
      firstPin,
      indexedContent,
      pinHashes: indexedPins.map((indexedPin) => indexedPin.hash).sort(),
    };
  }

  export function from(
    lesson: Lesson,
    pins: IndexedPin[],
    statistics: LessonStats,
    curriculaDict: Curriculum.ReferenceDict,
    channel: Channel | undefined,
    cluster: ClusterData | undefined,
    hashFunc: (value: string) => string,
    lessonMetaData?: FacetMetaData,
    user?: MongoUser
  ): IndexedLesson | undefined {
    const baseLesson = base(lesson, pins, channel?._id);

    if (!baseLesson) {
      return undefined;
    }

    const hash = hashFunc(
      pins
        .map((p) => p.hash)
        .sort()
        .join('|')
    );

    const metaData =
      lessonMetaData ||
      FacetMetaData.fromLesson({ pins: pins.map((indexedPin) => indexedPin.pin), lesson, user, channel });

    const channelInfo = IndexedChannelInfo.fromChannel(channel);

    const indexedContent = IndexedContent.addContentForMultiMatch(
      baseLesson.indexedContent,
      lesson.name,
      metaData,
      channelInfo
    );

    return {
      ...baseLesson,
      indexedContent,
      docType: 'lesson',
      metaData,
      statistics,
      channel: channelInfo,
      hash,
      cluster: cluster || {},
      curricula: CurriculumData.fromLessonCurriculaRef(lesson.curricula, curriculaDict),
      contentTypes: _.uniq(pins.map((doc) => doc.pin.item.type)),
    };
  }
}

// Document Sources can't have _id properties according to Elastic.
export type DocumentSource<T> = T extends Identifiable ? Omit<T, '_id'> : T;

export interface ElasticLessonDetails {
  item: IndexedLesson;
  pins: LessonPin[];
  moreLikeThis: SearchResultItem<IndexedLesson>[];
  channel?: Channel;
}

export namespace ElasticLessonDetails {
  /** returns all lesson ids in this object */
  export function lessonIds(details: ElasticLessonDetails): string[] {
    return details.moreLikeThis.map((i) => i._id).concat(details.item._id);
  }
}
