import { trackAction } from '@lessonup/client-integration';
import { color, css, styled } from '@lessonup/ui-components';
import qs from 'qs';
import React, { useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';
import { registerRoute, RegistrationStep, withQueryString } from '../../../shared-core/services/app/searchRoutes';
import { teacherHome } from '../../../shared-core/services/app/teacherRoutes';
import { ServiceComponentProps } from '../../components/appServices/WithServices';
import { loggedInUser } from '../../redux/selectors';
import { RedirectService } from '../../services/RedirectService';
import pageTitle from '../../utils/pageTitle';
import GrayPageLayout from '../GrayPageLayout/GrayPageLayout';
import './RegistrationPage.less';
import { RegistrationPageView, RegistrationPageViewProps } from './RegistrationPageView';
import { PRE_REGISTRATION_STEPS, RegistrationPageProps } from './types';
import { nextRegistrationStep } from './utils/nextRegistrationStep';
import { useRegistrationContext } from './utils/RegistrationContext';

const TRANSLATION_NS = 'registrationPage';

const DEFAULT_STEP: RegistrationStep = 'select';

type Props = ServiceComponentProps & RouteComponentProps & RegistrationPageProps;

const RegistrationPage: React.FunctionComponent<Props> = ({
  history,
  step,
  services,
  organizationId,
  nextPage,
  referralToken,
}) => {
  const registrationTracked = useRef(false);
  const user = useSelector(loggedInUser());
  const { domainRuleSuggestions, demoLesson } = useRegistrationContext();
  const { search } = useLocation();

  const goToStep = useCallback(
    (nextStep: RegistrationStep, organizationId?: string) => {
      const route = withQueryString(
        nextPage
          ? nextPage({
              step: nextStep,
              organizationId,
            })
          : registerRoute(nextStep, false, undefined, organizationId),
        search
      );

      shouldReplaceHistory(step) ? history.replace(route) : history.push(route);
    },
    [history, step, nextPage]
  );

  useEffect(() => {
    RedirectService.setRedirectUrlFromQueryString(history.location.search);

    if (registrationTracked.current) {
      return;
    }

    trackAction('register-start');
    registrationTracked.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!step || (!user && !PRE_REGISTRATION_STEPS.includes(step))) {
      if (referralToken) return goToStep('referral');
      goToStep('select');
    }
  }, [goToStep, history, step, user, referralToken]);

  const onAcceptTerms = () => services.user.onAcceptTerms();
  const acceptEmails = () => services.user.setAcceptEmails();

  const next = () => {
    const nextStep = nextRegistrationStep(step, {
      demoLesson,
      domainRuleSuggestions,
    });
    if (!nextStep) {
      return navigateOnRegistrationFinished();
    }

    goToStep(nextStep);
  };

  function navigateOnRegistrationFinished() {
    const redirectUrl = RedirectService.getRedirectUrl();
    if (redirectUrl) {
      window.open(redirectUrl, '_self');
      return;
    }
    if (referralToken) {
      goToStep('registration-gift');
      return;
    }

    window.open(withQueryString(teacherHome(), qs.stringify({ 'welcome-tour': true })), '_self');
  }

  const viewModel = (): RegistrationPageViewProps => {
    return {
      next,
      step: step || DEFAULT_STEP,
      onAcceptTerms,
      acceptEmails,
      goToStep,
      organizationId,
      referralToken,
    };
  };

  return (
    <StyledBackground className="registration-page" step={step}>
      <RegistrationPageMetaTags />
      <RegistrationPageView {...viewModel()} />
    </StyledBackground>
  );
};

function RegistrationPageMetaTags() {
  const { t } = useTranslation(TRANSLATION_NS);

  return (
    <Helmet>
      <title>{pageTitle(t('pageTitle'))}</title>
    </Helmet>
  );
}

function shouldReplaceHistory(currentStep?: RegistrationStep): boolean {
  if (!currentStep) return false;
  return currentStep !== 'select' && currentStep !== 'demo-lesson';
}

export default RegistrationPage;

const StyledBackground = styled(GrayPageLayout)<Pick<Props, 'step'>>`
  /* Design is testing out the gradient background for the referral page only. When using this a second time it should be moved to something like the foundations */
  background: ${({ step }) =>
    step && ['referral', 'registration-gift'].includes(step)
      ? css`linear-gradient(159deg, #cad9ff 0%, #b9b8ff 100%)`
      : color.neutral.surface.background};
`;
