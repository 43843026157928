/* eslint-disable max-len */
import { Lesson } from '@lessonup/teaching-core';
import { naiveStripHTML } from '@lessonup/utils';
import pageTitle from './pageTitle';

export function seoLessonPageTitle(lesson: Pick<Lesson, '_id' | 'name'>): string {
  return labsSeoData[lesson._id]?.pageTitle ?? (lesson.name ? pageTitle(lesson.name) : undefined) ?? '';
}

export function seoLessonMetaDescription(lesson: Pick<Lesson, '_id' | 'description'>): string | undefined {
  return labsSeoData[lesson._id]?.metaDescription || naiveStripHTML(lesson.description ?? '') || undefined;
}

/**
 * for LABS only. Will be removed soon! We override a couple of titles and meta descriptions to see what it will do to the SEO.
 */
const labsSeoData: Record<string, { pageTitle: string; metaDescription: string } | undefined> = {
  eh3KQAxZXu7XN6FRu: {
    pageTitle: 'Tropisch Regenwoud - Online Les voor Docenten & Leerlingen',
    metaDescription:
      'Voor docenten en leerlingen: leer alles over het tropisch regenwoud. Ontdek het klimaat en biodiversiteit in deze interactieve les op LessonUp.',
  },
  MD4YdPeC6e9RebxLc: {
    pageTitle: 'Savanne, Steppe & Woestijn: Klimaat en Kenmerken in de Klas',
    metaDescription:
      'Wat is het verschil tussen savanne, steppe en woestijn? Leer het met je klas in deze gratis online aardrijkskunde les op LessonUp.',
  },
  dhMtq9scSdZTu9DfM: {
    pageTitle: 'Vier Productiesectoren - Duidelijke Uitleg voor Scholieren',
    metaDescription:
      'Primaire, secundaire, tertiaire en quartaire sectoren: begrijp hoe onze economie werkt met deze interactieve les, speciaal voor scholieren en docenten.',
  },
  sXobhtS3u5nRjpdmr: {
    pageTitle: 'Tegenargumenten Online Les - Oefen in een Betoog',
    metaDescription:
      'Sterke argumenten formuleren in een betoog? Leer hoe je tegenargumenten herkent en weerlegt in deze praktische online les voor scholieren en docenten.',
  },
  AqT8byw2gLdyGrziY: {
    pageTitle: 'Microscoop Onderdelen en Functies: Interactieve Biologie Les',
    metaDescription:
      'Hoe werkt een microscoop? Ontdek de onderdelen en functies met duidelijke uitleg en interactieve opdrachten in deze online biologie les.',
  },
  Z8xsWyrDs6nREqLtr: {
    pageTitle: 'Naam in Graffiti Ontwerpen? Creatieve Les voor Leerlingen',
    metaDescription:
      'Creatieve lesopdracht: ontwerp je eigen naam in graffiti-stijl. Volg de stap-voor-stap instructies en ontdek de wereld van graffiti in de klas.',
  },
  '2Gtmhx7AHQfkAEhyn': {
    pageTitle: 'Lange & Korte Klanken Oefenen? Les over Spellingregels',
    metaDescription:
      'Leerlingen laten oefenen met lange en korte klanken? Gebruik deze interactieve spellingles en laat ze de regels spelenderwijs ontdekken.',
  },
  X5HXbSF9E2Rb5saJP: {
    pageTitle: 'Compositie in Kunst Begrijpen? Leer de Basis in Online Les',
    metaDescription:
      'Wat is compositie in kunst? Leer over beeldaspecten en hoe je een krachtig visueel ontwerp maakt met deze interactieve les voor in de klas.',
  },
  '6Sz8Tp9GHdtDEc9xY': {
    pageTitle: 'Wat beïnvloedt Temperatuur? Interactieve Aardrijkskunde Les',
    metaDescription: `Ontdek hoe breedteligging, wind, zee en gebergten de temperatuur beïnvloeden. Deze interactieve les voor vmbo & mavo leerjaar 1 bevat quizzen en video's.`,
  },
  snwY5N6MFrMDY3uwW: {
    pageTitle: 'Leer over de Organen van Vertering – Interactieve Les',
    metaDescription:
      'Ontdek de functies van het verteringsstelsel en zijn organen in deze interactieve biologie les voor vmbo leerjaar 2',
  },
  vvAQGMR6pkP8uHohH: {
    pageTitle: 'Analyseer Filmtechnieken en Verhaallijn - Interactieve Les',
    metaDescription:
      'In deze gratis online les op LessonUp analyseren middelbare scholieren een korte film. Ideaal voor docenten en leerlingen.',
  },
};
