import { Colors } from '@lessonup/teaching-core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { isString } from 'util';
import Checkmark from '../../../../shared-core/ui/components/svgIcons/Checkmark';
import './CheckboxGroup.less';

interface Props {
  id: string;
  initiallySelected?: boolean;
  onChangeSelect: (id: string, selectState: boolean) => void;
  label: string | React.JSX.Element;
  theme?: 'regular' | 'accented-border';
}

export default function CheckboxGroup(props: Props) {
  const [selected, setSelected] = useState<boolean>(props.initiallySelected || false);

  function toggleSelectHandler(e: React.MouseEvent | React.TouchEvent) {
    setSelected(!selected);
    props.onChangeSelect(props.id, !selected);
  }

  function getLabel() {
    if (isString(props.label)) {
      return <span dangerouslySetInnerHTML={{ __html: props.label }}></span>;
    }

    return props.label;
  }

  return (
    <div
      className={classNames('checkbox-group', { 'checkbox-accented-border': props.theme === 'accented-border' })}
      onClick={toggleSelectHandler}
    >
      <div className={`checkbox ${selected ? 'checkbox--checked' : ''}`}>
        {props.theme === 'accented-border' ? (
          selected && <Checkmark fill={Colors.textToHexMap['cyan']} />
        ) : (
          <div className="checkmark"></div>
        )}
      </div>
      <div className="label">{getLabel()}</div>
    </div>
  );
}
