import React from 'react';

import { AssignmentPin } from '@lessonup/teaching-core';
import { ContentTypeDefaults } from '../../../../utils/ContentTypeDefaults';
import ImageComponent, { ImageComponentPropsForPin } from '../../images/ImageComponent';
import PinContainer from '../../pin/PinContainer';
import { MapPinProps } from '../../pin/PinView';
import TextNewLineToBr from '../../text/TextNewLineToBr';
import ViewComponents from '../../viewComponents/ViewComponents';
import './Poll.less';

export interface BasePollProps extends MapPinProps {
  pin: AssignmentPin.Poll;
  isPlayer?: boolean;
  isThumb?: boolean;
  renderComponents?: (pin: AssignmentPin.Poll, isPlayer?: boolean, isThumb?: boolean) => React.JSX.Element;
  children?: React.ReactNode;
}

const BasePollView: React.FC<BasePollProps> = (props) => {
  const { item } = props.pin;

  const questionClasses = () => {
    const pinContent = item.custom;

    const textAlignClass = `text-align-${pinContent.align}`;
    const fontSize = pinContent.fontSize1 ? pinContent.fontSize1 : ContentTypeDefaults.getDefaultFontSize(1, item.type);
    const fontSizeClass = `font-size-${fontSize}`;

    return ['question poll-question', textAlignClass, fontSizeClass].join(' ');
  };

  const imageProps = ImageComponentPropsForPin(props.pin, 'image', props.isThumb);

  function components() {
    return props.renderComponents ? (
      props.renderComponents(props.pin, props.isPlayer, props.isThumb)
    ) : (
      <ViewComponents
        components={item.components}
        isThumb={props.isThumb}
        expandOnlyOne={item.settings?.closeOtherHotspots}
      />
    );
  }

  return (
    <PinContainer pin={props.pin}>
      {item.image && <ImageComponent {...imageProps} />}

      <div id="title" className={questionClasses()}>
        <TextNewLineToBr string={item.custom.question || ''} />
      </div>
      <div className="component-area">
        <div className="scale-up">{components()}</div>
      </div>
      {props.children}
    </PinContainer>
  );
};

export default BasePollView;
