import { tracker } from '@lessonup/analytics';
import { logger, trackAction } from '@lessonup/client-integration';
import { openChatWindow } from '@lessonup/intercom';
import { RegistrationCredentials } from '@lessonup/teacher-modern';
import { Button, useErrorContext } from '@lessonup/ui-components';
import { AppError } from '@lessonup/utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LanguageSingleton } from '../../../../shared-core/domain';
import { loginRoute } from '../../../../shared-core/services/app/searchRoutes';
import { useOnMountEffect } from '../../../../shared-core/ui/utils/hooks/useOnMountEffect';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { loggedInUser } from '../../../redux/selectors';
import { meteorRouteWithLanguageQueryParam } from '../../../utils/routeUtils/routeUtils';
import { RegistrationFormProps, RegistrationPageProps } from '../types';
import { useRegistrationContext } from '../utils/RegistrationContext';
import { useRedirectForLoggedInUsers } from '../utils/useRedirectForLoggedInUsers';
import { handleRegisterSSO } from './utils/registration.utils';

const TRANSLATION_NAMESPACE = 'registration';
type Props = RegistrationFormProps & RegistrationPageProps;

export const RegistrationCredentialsServiceWrapper = (props: Props) => {
  const user = useSelector(loggedInUser());
  const services = useAppServices();
  const { config } = services;
  const { setError } = useErrorContext();
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const [loading, setLoading] = useState<boolean>(false);
  const { email, setEmail } = useRegistrationContext();
  const studentAppUrl = config.studentRegistrationUrl();

  useRedirectForLoggedInUsers();

  useOnMountEffect(() => {
    tracker.events.registrationStart({ referralToken: props.referralToken });
    trackAction('register-credentials-start');
  });

  useEffect(() => {
    if (user) {
      props.goToStep('terms');
    }
  }, [props, user]);

  const submitRegistration = async (params: { email: string }): Promise<void> => {
    setLoading(true);
    try {
      await handleSubmitEmail(params.email);
    } catch (error) {
      logger.error(error);
      tracker.trackError(error);
      tracker.events.registrationError({ errorDescription: error.toString() });
      setError({
        error,
        displayMode: 'toast',
        customErrorDisplayMessage: {
          title: t('credentialsStep.errors.genericTitle'),
          description: t('credentialsStep.errors.genericDetail'),
          button: (
            <Button buttonType="neutral" onClick={openChatWindow}>
              {t('credentialsStep.errors.genericCTAText')}
            </Button>
          ),
        },
      });
      return;
    } finally {
      setLoading(false);
    }
  };

  async function handleSubmitEmail(email: string): Promise<void> {
    const { processed } = await services.user.sendEmailVerificationEmail({
      email,
      language: LanguageSingleton.get(),
    });

    if (!processed) throw new AppError('unknown', 'Email validation send request not processed');

    tracker.events.registrationVerificationStart();
    setEmail(email);

    props.next();
  }

  return (
    <RegistrationCredentials
      redirectToStudent={meteorRouteWithLanguageQueryParam(studentAppUrl)}
      redirectToLogin={loginRoute('select')}
      submitRegistration={submitRegistration}
      handleRegisterSSO={handleRegisterSSO(props.referralToken)}
      loading={loading}
      initialEmail={email}
    />
  );
};
