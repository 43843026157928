import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Bucket, SearchParams } from '../../../../shared-core/domain';
import { Curriculum, CurriculumChild, getListByYear } from '../../../../shared-core/domain/curricula/Curricula';
import { channelRoute } from '../../../../shared-core/services/app/searchRoutes';

interface ChannelCurriculaRenderer {
  curriculum: Curriculum;
  slug: string;
  counts: Bucket[];
}

type CountsDict = Record<string, Bucket>;
const ChannelCurriculaRenderer: React.FC<ChannelCurriculaRenderer> = ({ slug, curriculum, counts }) => {
  const countsById: CountsDict = useMemo(() => _.keyBy(counts, 'key'), [counts]);
  const { t } = useTranslation('curricula');

  return (
    <div className="curricula-content">
      <h1 id={curriculum._id}>{curriculum.name}</h1>
      {curriculum.settings.orderByYear ? (
        <CurriculaFiltering curriculum={curriculum} counts={countsById} slug={slug} />
      ) : (
        <CurriculaSection
          curriculumId={curriculum._id}
          children={curriculum.children}
          collapsed={curriculum.settings.startCollapsed}
          isSection={true}
          slug={slug}
          counts={countsById}
          t={t}
        />
      )}
    </div>
  );
};

export default ChannelCurriculaRenderer;

interface SectionProps {
  slug: string;
  collapsed: boolean;
  children: CurriculumChild[];
  curriculumId: string;
  isSection?: boolean;
  counts: CountsDict;
  t: TFunction;
}

const CurriculaSection: React.FC<SectionProps> = ({
  slug,
  children,
  collapsed,
  isSection,
  counts,
  t,
  curriculumId,
}) => {
  const collapseClassname = 'collapse-section';
  return (
    <ul className="curricula-section">
      {children
        ? children.map((sectionChild, i) => {
            if (isSection) {
              return (
                <li key={sectionChild._id}>
                  <Collapse
                    key={sectionChild._id}
                    nr={sectionChild.nr}
                    name={sectionChild.name}
                    collapsed={collapsed}
                    className={collapseClassname}
                  >
                    <CurriculaSection
                      key={sectionChild._id}
                      children={sectionChild.children}
                      curriculumId={curriculumId}
                      collapsed={collapsed}
                      counts={counts}
                      slug={slug}
                      t={t}
                    />
                  </Collapse>
                </li>
              );
            } else {
              return (
                <CurriculumChildren
                  key={sectionChild._id}
                  child={sectionChild}
                  counts={counts}
                  slug={slug}
                  curriculumId={curriculumId}
                />
              );
            }
          })
        : null}
    </ul>
  );
};

interface ChildrenProps {
  child: CurriculumChild;
  curriculumId: string;
  slug: string;
  counts: CountsDict;
}

function urlForObjective(slug: string, id: string, curriculumId: string) {
  const { queryString } = SearchParams.searchParamsToUrlComponents(
    SearchParams.fromDictionary(
      {
        objective: id,
        curriculum: curriculumId,
      },
      'lessons',
      ['channelFacets']
    )
  );

  return channelRoute({
    channelSlug: slug,
    channelPage: 'search',
    selectionId: 'lessons',
    qs: queryString,
  });
}

const CurriculumChildren: React.FC<ChildrenProps> = ({ slug, child, counts, curriculumId }) => {
  const history = useHistory();
  const { name, description, nr, _id } = child;
  const [collapsed, setCollapsed] = React.useState(false);
  const { children } = child;

  const style = {
    collapsedChild: {
      display: 'none',
    },
    expanded: {
      display: 'block',
    },
  };
  const hasLessons = !!(counts[_id] && counts[_id].doc_count);
  const url = urlForObjective(slug, child._id, curriculumId);
  function handleClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    if (!hasLessons) return;
    event.stopPropagation();
    history.push(url);
  }

  function handleDescriptionClick(event) {
    event.stopPropagation();
    setCollapsed(!collapsed);
  }

  return (
    <li
      className={classNames('curricula-child', { 'child-description': !!description, collapsed, pointer: hasLessons })}
      onClick={handleClick}
    >
      <div className="curricula-child-info">
        <span className="curricula-child-nr">{nr}</span>
        <span className="curricula-child-name">
          {name} <Count counts={counts} id={_id} url={url} />
        </span>
        {description ? (
          <div
            className="curricula-description-icon icon icon-dark icon-questionmark"
            onClick={handleDescriptionClick}
          ></div>
        ) : null}
      </div>
      {description ? (
        <div
          className="curricula-description-content collapse-content"
          style={collapsed ? style.expanded : style.collapsedChild}
          aria-expanded={collapsed}
        >
          <p>
            <i>{description}</i>
          </p>
        </div>
      ) : null}
      {children ? (
        <ul>
          {children.map((child, i) => {
            return (
              <CurriculumChildren
                key={child._id}
                child={child}
                counts={counts}
                slug={slug}
                curriculumId={curriculumId}
              />
            );
          })}
        </ul>
      ) : null}
    </li>
  );
};

const Count: React.FC<{ counts: CountsDict; id: string; url: string }> = ({ counts, id, url }) => {
  const { t } = useTranslation('common');

  return counts[id] ? (
    <Link to={url} className="count">
      {t('lessonCount', { count: counts[id].doc_count, ns: 'common' })}{' '}
    </Link>
  ) : null;
};

interface FilterProps {
  curriculum: Curriculum;
  slug: string;
  counts: CountsDict;
}

const CurriculaFiltering: React.FC<FilterProps> = ({ slug, curriculum, counts }) => {
  const { t } = useTranslation('curricula');
  const [byYear, setByYear] = React.useState(true);
  const listByYear = getListByYear(curriculum);
  const collapseClassname = 'collapse-groupyear';
  const buttonClassActive = 'action-button-primary button-active';
  const buttonClass = 'action-button-primary button-inactive';
  if (!listByYear) return null;

  return (
    <ul>
      <div className="curricula-filters">
        <button className={byYear ? buttonClassActive : buttonClass} onClick={() => setByYear(true)}>
          {t('sortByYear')}
        </button>
        <button className={!byYear ? buttonClassActive : buttonClass} onClick={() => setByYear(false)}>
          {t('sortByDomain')}
        </button>
      </div>
      {byYear ? (
        listByYear.map((group, i) => {
          return (
            <li key={group.name}>
              <Collapse name={group.name} className={collapseClassname} collapsed={curriculum.settings.startCollapsed}>
                <CurriculaSection
                  slug={slug}
                  curriculumId={curriculum._id}
                  children={group.children}
                  collapsed={false}
                  isSection={true}
                  counts={counts}
                  t={t}
                />
              </Collapse>
            </li>
          );
        })
      ) : (
        <CurriculaSection
          slug={slug}
          children={curriculum.children}
          curriculumId={curriculum._id}
          collapsed={curriculum.settings.startCollapsed}
          isSection={true}
          counts={counts}
          t={t}
        />
      )}
    </ul>
  );
};

interface CollapseProps {
  className: string;
  collapsed?: boolean;
  name?: string;
  nr?: number | string;
  children?: React.ReactNode;
}

const Collapse: React.FC<CollapseProps> = (props) => {
  const [collapsed, setCollapsed] = React.useState(props.collapsed);
  const className = props.className;

  const style = {
    collapsedChild: {
      display: 'none',
    },
    expanded: {
      display: 'block',
    },
  };

  return (
    <div className={className}>
      <div className={'collapse-name'} onClick={() => setCollapsed(!collapsed)}>
        <h2>
          {props.nr ? <span className="curricula-nr"> {props.nr}</span> : null}{' '}
          {props.name ? <span className="curricula-name"> {props.name} </span> : null}
        </h2>
      </div>
      <div
        className="collapse-content"
        style={collapsed ? style.collapsedChild : style.expanded}
        aria-expanded={collapsed}
      >
        {props.children}
      </div>
    </div>
  );
};
