import React, { Children, cloneElement, isValidElement } from 'react';
import { useHistory } from 'react-router';

export function LinkBridge({ href, children, onClick, passHref, external }: UIComponents.LinkProps) {
  const history = useHistory();

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const url = href.toString();
    if (onClick && typeof onClick === 'function') {
      onClick(event);
      if (!url) {
        return;
      }
    }
    if (external) window.location = url as any;
    else history.push(url);
  };

  const childrenWithProps = Children.map(children, (child) => {
    if (!isValidElement(child)) return child;

    const childProps: {
      onClick: React.MouseEventHandler;
      href?: string;
    } = {
      onClick: handleClick,
    };

    if (child.type === 'a' || passHref) {
      childProps.href = href;
    }

    return cloneElement(child, childProps);
  });

  return <>{childrenWithProps}</>;
}

declare global {
  namespace UIComponents {
    export interface CustomLinkProps {
      // required in search
      external: boolean;
    }
  }
}
