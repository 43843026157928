import {
  EduSystem,
  formatLevel,
  formatSchoolType,
  formatYears,
  getSchoolTypePrefix,
  PinUtils,
} from '@lessonup/teaching-core';
import _ from 'lodash';
import { FacetSearchResponseType } from '.';
import capitalize from '../../utils/capitalize';
import { PinViewUtils } from '../../utils/PinViewUtils';
import { Country } from '../country/Country';
import { Language } from '../language';
import { Bucket } from './ElasticResponseBody';
import { IndexType } from './IndexType';

export namespace Facets {
  const metaDataFacets: Facet[] = [
    { key: 'subjects', field: 'metaData.subjects.keyword', valueType: 'string' },
    { key: 'schoolType', field: 'metaData.schoolType', valueType: 'string' },
    { key: 'schoolTypes', field: 'metaData.schoolType', custom: true, valueType: 'string' },
    { key: 'levels', field: 'metaData.levels', valueType: 'string' },
    { key: 'years', field: 'metaData.years', valueType: 'string' },
    { key: 'country', field: 'metaData.country', valueType: 'string' },
    { key: 'organizations', field: 'metaData.organizations', fixed: true, custom: true, valueType: 'string' },
  ];

  const subTypes = ['channelFacets', 'apiLessons'] as const;
  export type FacetSubTypes = (typeof subTypes)[number];
  export const allSubTypes = () => [...subTypes] as FacetSubTypes[];

  function facetsForSubType(subTypes: FacetSubTypes[] | undefined, index: IndexType): Facet[] {
    if (!subTypes) return [];
    return _.flatMap(
      _.compact(
        subTypes.map((subType) => {
          if (subType === 'apiLessons') {
            return [
              { key: 'contentType', field: 'contentTypes', valueType: 'string' },
              { key: 'channel', field: 'channel.name.keyword', valueType: 'string' },
            ];
          }
          if (subType === 'channelFacets') {
            return [
              { key: 'channel', field: 'channel.name.keyword', fixed: true, valueType: 'string' },
              index === 'lessons'
                ? { key: 'tag', field: 'lesson.tags.keyword', hideIfEmpty: true, valueType: 'string' }
                : null,
              {
                key: 'curriculum',
                field: 'curricula.list',
                hideIfEmpty: true,
                nested: {
                  field: 'id',
                  labelField: 'label',
                },
                valueType: 'string',
              },
              {
                key: 'objective',
                field: 'curricula.objectives',
                hideIfEmpty: true,
                nested: {
                  field: 'id',
                  labelField: 'label',
                },
                valueType: 'string',
              },
            ];
          }
        })
      )
    );
  }

  export function facetsForType<T extends IndexType>(index: T, subType?: FacetSubTypes[]): Facet[] {
    if (index === 'lessons') {
      return [
        ...metaDataFacets,
        { key: 'isTest', field: 'lesson.isTest', valueType: 'boolean' },
        { key: 'isChannelLesson', field: 'channel.channelPublic', valueType: 'boolean' },
        ...facetsForSubType(subType, index),
      ];
    } else if (index === 'plans') {
      return [...metaDataFacets, { key: 'channel', field: 'channel.name.keyword', fixed: true, valueType: 'string' }];
    } else if (index === 'pins') {
      return [
        ...metaDataFacets,
        { key: 'type', field: 'pin.item.type', valueType: 'string' },
        { key: 'hasImage', field: 'images.hasImage', valueType: 'boolean' },
      ];
    }
    return [];
  }

  /**
   * Returns the facets for the given index type, but with the keys mapped to the Atlas search fields.
   * @param index Index type
   * @param subType Additional facet subtypes to include
   * @returns Facets with the keys mapped to the Atlas search fields
   */
  export function atlasSearchFacetsForType<T extends IndexType>(index: T, subType?: FacetSubTypes[]): Facet[] {
    return facetsForType(index, subType).map((facet) => {
      switch (facet.key) {
        case 'subjects':
          return { ...facet, field: 'metaData.subjects' };
        case 'tag':
          return { ...facet, field: 'lesson.tags' };
        case 'channel':
          return { ...facet, field: 'channel.name' };
        default:
          return facet;
      }
    });
  }

  export function find<T extends IndexType, K extends FacetKey>(
    result: FacetSearchResponseType<T> | undefined,
    key: K
  ) {
    if (!result) return undefined;
    return result.facets.find((f) => f.key === key);
  }

  export function mapSelectedFacetsToObject(
    selectedFacets: SelectedFacet[]
  ): Partial<Record<FacetKey, string | undefined>> {
    return selectedFacets.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {});
  }

  export function facetLabel(
    facet: SingleValueSelectedFacet | { key: 'text'; value: string },
    country: string,
    schoolType?: string
  ): string | undefined {
    const eduSystem = EduSystem.get(country);
    switch (facet.key) {
      case 'text':
        return facet.value;
      case 'subjects':
        return facet.value;
      case 'country':
        return Country.lookupCountryByAbbreviation(facet.value, true);
      case 'schoolType':
        return formatSchoolType(eduSystem, facet.value);
      case 'isTest':
        return facet.value ? 'test' : '';
      case 'isChannelLesson':
        return facet.value ? 'channelLesson' : '';
      case 'levels':
        return formatLevel(eduSystem, schoolType, facet.value);
      case 'years':
        return formatYears(eduSystem, schoolType, parseInt(facet.value, 10));
      case 'contentType':
        if (PinUtils.isPinType(facet.value)) {
          return PinViewUtils.labelForPinType(facet.value, Language.defaultLanguageForCountry(country));
        }
      default:
        return facet.value;
    }
  }

  export function obtainMetaDataInfoForSelectedFacets(selectedFacets: SelectedFacet[]): MetaDataInfo {
    const facets = Facets.mapSelectedFacetsToObject(selectedFacets);
    const { subjects, country = 'nl' } = facets;
    const valueFacets = selectedFacets.filter(isSelectedValueFacet);

    const [schoolType, years, levels] = ['schoolType', 'years', 'levels'].map((key) =>
      valueFacets.find((f) => f.key === key)
    );

    const formattedSchoolType = country && schoolType && facetLabel(schoolType, country);
    const schoolTypePrefix = schoolType && getSchoolTypePrefix(country, schoolType.value);
    const formattedYear = country && years && facetLabel(years, country, schoolType && schoolType.value);
    const formattedLevel = country && levels && facetLabel(levels, country, schoolType && schoolType.value);

    const yearAndLevel = combinedYearLevel(formattedYear, formattedLevel);
    const formattedSubject = subjects && capitalize(subjects);

    return {
      schoolType: formattedSchoolType,
      yearAndLevel,
      subject: formattedSubject,
      schoolTypePrefix,
      country: facets.country,
    };
  }

  export function combinedYearLevel(year: string | undefined, level: string | undefined): string | undefined {
    if (!year && !level) return;

    if (year && level) return `${level} ${year.toLowerCase()}`;
    // TypeScript doesn't infer that if level is falsey, year must be truthy.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return !level ? year!.toLowerCase() : level;
  }

  /**
   * Code below defines dependencies of facets both ways
   */
  const dependents: Partial<Record<FacetKey, FacetKey[]>> = {
    schoolType: ['years', 'levels'],
    curriculum: ['objective'],
  };
  const dependencies = Object.entries(dependents).reduce<Partial<Record<FacetKey, FacetKey>>>((mem, [key, deps]) => {
    // eslint-disable-next-line no-param-reassign
    (deps || []).forEach((v) => (mem[v] = key as FacetKey));
    return mem;
  }, {});

  export function facetsHasUnmetDependency(facetKey: FacetKey, selectedFacets: SelectedFacet[]): boolean {
    if (!dependencies[facetKey]) return false;
    return !selectedFacets.some((s) => s.key === dependencies[facetKey]);
  }

  export function dependentKeys(facetKey: FacetKey): FacetKey[] | undefined {
    return dependents[facetKey];
  }
}

export type DefaultUrlFacets = 'subjects' | 'schoolType' | 'levels' | 'years' | 'country';
export type ValueFacetKey =
  | DefaultUrlFacets
  | 'isTest'
  | 'type'
  | 'hasImage'
  | 'source'
  | 'isChannelLesson'
  | 'channel'
  | 'contentType'
  | 'objective'
  | 'curriculum'
  | 'tag'
  | 'isTest'
  | 'isChannelLesson';

export type ArrayFacetKey = 'organizations' | 'schoolTypes';
const arrayFacets: ArrayFacetKey[] = ['organizations', 'schoolTypes'];

export type FacetKey = ValueFacetKey | ArrayFacetKey;

export interface Facet {
  key: FacetKey;
  field: string;
  fixed?: boolean;
  hideIfEmpty?: boolean;
  custom?: boolean;
  nested?: {
    field: string;
    labelField?: string;
  };
  valueType: 'string' | 'boolean';
}

export interface SingleValueSelectedFacet {
  key: ValueFacetKey;
  value: string;
  fixed?: boolean;
}

export interface ArraySelectedFacet {
  key: ArrayFacetKey;
  value: string[];
  fixed?: boolean;
}

export type SelectedFacet = SingleValueSelectedFacet | ArraySelectedFacet;

export function isValueFacet(key: FacetKey): key is ValueFacetKey {
  return !arrayFacets.includes(key as any);
}
export function isArrayFacet(key: FacetKey): key is ArrayFacetKey {
  return arrayFacets.includes(key as any);
}

export function isSelectedValueFacet(facet: SelectedFacet): facet is SingleValueSelectedFacet {
  return isValueFacet(facet.key);
}
export function isSelectedArrayFacet(facet: SelectedFacet): facet is ArraySelectedFacet {
  return isArrayFacet(facet.key);
}

export interface FacetResult {
  key: FacetKey;
  selected: SelectedFacet | undefined;
  items: Bucket[];
  isDisabled?: boolean;
  hideIfEmpty?: boolean;
}

export interface MetaDataInfo {
  schoolType: string | undefined;
  schoolTypePrefix: string | undefined;
  yearAndLevel: string | undefined;
  subject: string | undefined;
  country: string | undefined;
}
