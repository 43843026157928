import { stripLeadingSlash } from '@lessonup/utils';
import _ from 'lodash';
import qs from 'qs';
import { Channel, Language, LanguageSingleton, SearchUrlParams } from '../../../shared-core/domain';
import {
  channelRoute,
  languageKey,
  lessonPlanRoute,
  lessonSearchRoute,
  searchItemRoute,
  searchPlanRoute,
} from '../../../shared-core/services/app/searchRoutes';

export function routeIsAccountPage(currentPath: string): boolean {
  const searchAccountPages = ['register', 'voucher', 'login', 'forgot-password'];
  const pathWithoutLeadingSlash = stripLeadingSlash(currentPath);
  return _.includes(searchAccountPages, pathWithoutLeadingSlash.split('/')[1]);
}

export const withQueryString = (path: string, qs?: string) => {
  return qs ? (qs[0] === '?' ? `${path}${qs}` : `${path}?${qs}`) : path;
};

export const withLanguage = (route: string, language: string | undefined) => {
  return `/${language || LanguageSingleton.get()}${route}`;
};

export const withRedirectUrl = (baseUrl: string, redirectUrl: string) => {
  return withQueryString(baseUrl, `redirect-url=${encodeURIComponent(redirectUrl)}`);
};

const searchRouterKeys: SearchUrlParams = {
  country: ':country?',
  schoolType: ':schoolType?',
  subjects: ':subjects?',
  years: ':years?',
  levels: ':levels?',
};

export const routerSearchLessonsRoute = () =>
  lessonSearchRoute({
    ...searchRouterKeys,
    language: languageKey,
  });

export const routerSearchPlanRoute = () =>
  searchPlanRoute({
    ...searchRouterKeys,
    language: languageKey,
  });

export const routerSearchItemRoute = () =>
  searchItemRoute({
    ...searchRouterKeys,
    language: languageKey,
  });

// country en subSelectionId Id, share a spot
export const channelRouterRoute = () =>
  channelRoute({
    language: languageKey,
    channelSlug: ':channelSlug',
    channelPage: ':channelPage?',
    selectionId: ':selectionId?',
    subSelectionId: ':subSelectionId?', // country en subSelectionId Id, share a spot
    ...searchRouterKeys,
  });

export function meteorRouteWithLanguageQueryParam(route: string, lang: Language.Key = LanguageSingleton.get()): string {
  const query = route.includes('?') && route.split('?')[1];
  const queryWithLang = qs.stringify(query ? { ...qs.parse(query), lang } : { lang });

  return `${route}?${queryWithLang}`;
}

const isSearchRouteOfType = (path: string, route: string, index = 1) => {
  const lessonParts = stripLeadingSlash(route).split('/');
  const pathParts = stripLeadingSlash(path).split('/');
  return pathParts[index] === lessonParts[index] && Language.isValid(pathParts[0]);
};

export const isLessonSearchRoute = (path: string): boolean => {
  return isSearchRouteOfType(path, lessonSearchRoute());
};

export const isplanRoute = (path: string): boolean => {
  return isSearchRouteOfType(path, lessonPlanRoute('na'));
};

export const isChannelRouteOfType = (path: string, type: Channel.Page): boolean => {
  const route = channelRoute({
    channelSlug: 'na',
    channelPage: type,
  });
  // channel/slug/page
  return isSearchRouteOfType(path, route) && isSearchRouteOfType(path, route, 3);
};

export const popupExemptRoutes = ['/embed/', '/export/', '/search/lti/deeplinkpage', '/search/lti/unsupported-browser'];
