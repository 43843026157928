import { tracker } from '@lessonup/analytics';
import { logger, trackAction } from '@lessonup/client-integration';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LanguageKey, LanguageSingleton } from '../../../../shared-core/domain';
import { Country } from '../../../../shared-core/domain/country/Country';
import { UnitedStates } from '../../../../shared-core/domain/country/UnitedStates';
import ActionButton from '../../../../shared-core/ui/components/Button';
import { InputLabel } from '../../../../shared-core/ui/components/Input/InputLabel/InputLabel';
import { useFormInput } from '../../../../shared-core/ui/components/inputElements/useFormInput';
import CountryPicker from '../../../../shared-core/ui/components/pickers/CountryPicker';
import StatePicker from '../../../../shared-core/ui/components/pickers/StatePicker';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { RegistrationCheckboxes } from '../../../components/RegistrationCheckboxes/RegistrationCheckboxes';
import { geoLocation, loggedInUser } from '../../../redux/selectors';
import { GrayPageLayoutTitle, GrayPageSubtitle } from '../../GrayPageLayout/GrayPageLayout';
import { RegistrationFormProps } from '../types';
import { useRegistrationContext } from '../utils/RegistrationContext';
import './RegistrationTerms.less';

type Props = RegistrationFormProps & {
  onAcceptTerms: () => void;
  acceptEmails: () => void;
  language: LanguageKey;
  referralToken?: string;
};

const TRANSLATION_NS = 'register';

const entriesToOptions = <T extends UnitedStates.UnitedStatesTuple | Country.CountryTuple>(
  entries: T[]
): { value: T[0]; label: T[1] }[] => entries.map(([value, label]) => ({ value, label }));

export default function RegistrationTerms(props: Props): React.JSX.Element {
  const { t } = useTranslation(TRANSLATION_NS);
  const services = useAppServices();
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [emailAccepted, setEmailAccepted] = useState<boolean>(false);
  const user = useSelector(loggedInUser());
  const detectedLocale = useSelector(geoLocation());
  const { domainRuleSuggestions } = useRegistrationContext();

  useEffect(() => {
    trackAction('register-terms-start', { origin: 'register' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [givenName, givenNameInput] = useFormInput({
    autoComplete: 'given-name',
    type: 'text',
    requiredMsg: t('givenNameError'),
    label: t('givenName'),
    initial: user?.profile?.given_name,
  });

  const [familyName, familyNameInput] = useFormInput({
    autoComplete: 'family-name',
    type: 'text',
    requiredMsg: t('familyNameError'),
    label: t('familyName'),
    initial: user?.profile?.family_name,
  });

  const validatedDetectedLocale = Country.isCountryCode(detectedLocale?.countryCode) && detectedLocale?.countryCode;
  const requiresUSState = user?.country === 'us' || (!user?.country && validatedDetectedLocale === 'us');
  const language = LanguageSingleton.get();

  const countryOptions = useMemo(
    () => entriesToOptions<Country.CountryTuple>(Country.localRankedCountryEntries(props.language)),
    [props.language]
  );

  const stateOptions = useMemo(
    () => entriesToOptions<UnitedStates.UnitedStatesTuple>(UnitedStates.statesEntries()),
    []
  );

  function onSelect(id: 'terms' | 'email', selectState: boolean) {
    if (id === 'terms') {
      setTermsAccepted(selectState);
    } else {
      setEmailAccepted(selectState);
    }
  }

  function storeDetectedCountryIfApplicable() {
    if (user?.country || !validatedDetectedLocale) {
      return;
    }

    services.user.setCountry(validatedDetectedLocale);
  }

  function onClickHandler() {
    if (!termsAccepted || !givenName || !familyName) {
      logger.error('Tried to submit registration form without accepting terms or filling out name.');
      return;
    }

    props.onAcceptTerms();
    if (emailAccepted) {
      props.acceptEmails();
    }

    if (language && language !== user?.language) {
      services.user.setLanguage(language);
    }

    services.user.storeName({ givenName, familyName });

    storeDetectedCountryIfApplicable();
    tracker.events.createRegistrationAccount({ referralToken: props.referralToken });
    trackAction('register-terms-finished', { newsletterAccepted: !!emailAccepted });
    props.next();
  }

  const showDomainSuggestions = !!domainRuleSuggestions.length;
  const countryIsValid = showDomainSuggestions ? user?.country || validatedDetectedLocale : true;

  const isDisabled =
    !termsAccepted || !countryIsValid || (requiresUSState && !user?.USState) || !givenName || !familyName;

  return (
    <div className="registration-page--terms">
      <div className="registration-title">
        <GrayPageLayoutTitle>{t('registerAsTeacher')}</GrayPageLayoutTitle>
        <GrayPageSubtitle>{t('terms-description')}</GrayPageSubtitle>
      </div>
      <div className="registration-container registration-terms-container registration-container--flex-column">
        <div className={'name-field-container'}>
          {givenNameInput}
          {familyNameInput}
        </div>
        {showDomainSuggestions && (
          <div className="country-and-state-container">
            <div>
              <InputLabel text={t('select-country')} id="country-picker" disabled={false} />
              <CountryPicker
                id="country-picker"
                options={countryOptions}
                onChange={(country) => services.user.setCountry(country)}
                placeholder={t('selectCountryPlaceholder')}
                defaultValue={user?.country || validatedDetectedLocale || undefined}
              />
            </div>
            {requiresUSState && (
              <div>
                <InputLabel text={t('select-state')} id="state-picker" disabled={false} />
                <StatePicker
                  id="state-picker"
                  options={stateOptions}
                  onChange={(USState) => services.user.setUSState(USState)}
                  placeholder={t('selectStatePlaceholder')}
                  defaultStateCode={user?.USState}
                />
              </div>
            )}
          </div>
        )}
        <div className="registration-options">
          <RegistrationCheckboxes
            emailAccepted={emailAccepted}
            termsAccepted={termsAccepted}
            setEmailAccepted={(val) => onSelect('email', val)}
            setTermsAccepted={(val) => onSelect('terms', val)}
          />
        </div>
        <ActionButton block theme="secondary" disabled={isDisabled} onClick={onClickHandler} version="modern">
          {t('confirmButton')}
        </ActionButton>
      </div>
    </div>
  );
}
