import { isEqual } from 'lodash';
import { useRef } from 'react';

const useDeepCompareMemoize = <T>(value: T): T => {
  const valueRef = useRef<T>(value);
  if (!isEqual(value, valueRef.current)) {
    valueRef.current = value;
  }
  return valueRef.current as T;
};

export default useDeepCompareMemoize;
