import { tracker } from '@lessonup/analytics';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { AnchorButton } from '../../../shared-core/ui/components/Button';
import Heading from '../../../shared-core/ui/components/Heading';
import capitalize from '../../../shared-core/utils/capitalize';
import './appSelectionTiles.less';

interface Props {
  title: string;
  theme: 'red' | 'blue';
  className?: string;
  image: () => React.JSX.Element | null;
  children?: React.ReactNode;
}

const BaseTile: React.FC<Props> = (props) => {
  return (
    <div className="image-tile-wrapper">
      <div className={classNames('image-tile', props.theme, props.className)}>
        <div className="title">
          <Heading size="h1" as="h2" className="tile-title">
            {props.title}
          </Heading>
        </div>
        {props.image()}
        <div className="body stack">{props.children}</div>
      </div>
    </div>
  );
};

export const TeacherTile: React.FC<{
  next: () => void;
  imageClass?: string;
  hideImage?: boolean;
  children?: React.ReactNode;
}> = (props) => {
  const { t } = useTranslation('login');

  return (
    <BaseTile
      image={() =>
        props.hideImage ? null : (
          <img
            src="/search/public/svg/teacher.svg"
            alt="Teacher"
            className={classNames('tile-svg teacher-image', props.imageClass)}
          />
        )
      }
      title={capitalize(t('loginAsTeacher'))}
      theme="red"
      className="align-end teacher-tile"
    >
      {props.children}
      <Button className="button-grow" theme="primary" version="modern" onClick={() => props.next()}>
        {t('proceedAsTeacher')}
      </Button>
    </BaseTile>
  );
};

export const StudentTile: React.FC<{
  imageClass?: string;
  hideImage?: boolean;
  url: string;
  children?: React.ReactNode;
}> = (props) => {
  const { t } = useTranslation('login');

  return (
    <BaseTile
      image={() =>
        props.hideImage ? null : (
          <img
            src="/search/public/svg/students.svg"
            alt="Students"
            className={classNames('tile-svg', props.imageClass)}
          />
        )
      }
      title={capitalize(t('loginAsStudent'))}
      theme="blue"
      className="student-tile"
    >
      {props.children}
      <AnchorButton
        className="center-text button-grow"
        version="modern"
        href={props.url}
        theme="quaternary"
        onClick={() => tracker.events.studentNavigateToApp()}
      >
        {t('proceedAsStudent')}
      </AnchorButton>
    </BaseTile>
  );
};
