import { UserContent, UserContentPrivacy } from '@lessonup/teaching-core';
import { Licenses } from '../user/Licenses';
import { MongoUser } from '../user/MongoUser';

interface DocWithPrivacy {
  privacy?: UserContentPrivacy;
}
export namespace UserContentUtils {
  export function documentGivesChannelRights(doc: UserContent): boolean {
    return Boolean(doc?.origin?.channel && doc.origin.rights === 'givesProFeatures' && doc.origin.unmodified);
  }

  export function hasChannelRights(doc: UserContent | undefined, user: MongoUser | undefined): boolean {
    const isFree = Licenses.isFree(user);
    return Boolean(isFree && doc && documentGivesChannelRights(doc));
  }

  export function documentUnmodified(doc: UserContent): boolean {
    return Boolean(doc.origin?.unmodified);
  }

  export function isPrivate(doc: DocWithPrivacy | undefined): boolean {
    return Boolean(doc?.privacy === 'private');
  }

  export function isPublic(doc: DocWithPrivacy | undefined): boolean {
    return Boolean(doc?.privacy === 'public');
  }

  export const isPrivacySetting = (privacy: string | undefined): privacy is UserContentPrivacy => {
    if (!privacy) return false;
    const existingPrivacySettings = ['public', 'private', 'protected'];
    return existingPrivacySettings.includes(privacy);
  };
}
