import { useCallback, useEffect, useRef, useState } from 'react';
import { PinViewUtils } from '../../../utils/PinViewUtils';

interface WindowDimensions {
  width: number;
  height: number;
}

function getWindowDimensions(): WindowDimensions | undefined {
  if (typeof window == 'undefined') return;
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions(): WindowDimensions | undefined {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    if (!getWindowDimensions()) return;
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}

export function useFontSizeForRef() {
  const ref = useRef<HTMLElement>(null);
  const [fontSize, setFont] = useState<string | undefined>();
  const windowSize = useWindowDimensions();
  const setRef = useCallback((node: HTMLElement | null) => {
    if (!node) {
      return;
    }
    ref.current = node;
  }, []);

  useEffect(() => {
    if (!ref.current || !windowSize) return;
    const size = PinViewUtils.calcFontSizeForWindowWidth(ref.current.clientWidth, windowSize.width);
    if (size) setFont(size);
  }, [windowSize, ref]);

  const windowWidth = windowSize && windowSize.width;
  return { setRef, fontSize, windowWidth };
}
