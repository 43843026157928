import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { registerRoute } from '../../../shared-core/services/app/searchRoutes';
import ActionButton from '../../../shared-core/ui/components/Button';
import { Modal } from '../../../shared-core/ui/components/modals/Modal';
import LoginSelectApplication from '../../pages/login/loginSteps/LoginSelectApplication';
import { loggedInUser } from '../../redux/selectors';
import { AppUserService } from '../../services/AppServices';
import { LoginButtons } from './LoginButtons';
import './LoginModal.less';
import PasswordLogin from './PasswordLogin';

export interface LoginModalConfig {
  userService: AppUserService;
  initialPage?: Page;
}
export type LoginModalProps = LoginModalConfig & {
  closeModal: (res: LoginModalResult | undefined) => void;
};

export interface LoginModalResult {
  status: 'success' | 'failed';
  data?: {};
}

export interface ViewModel {
  iframeReady: boolean;
  userService: AppUserService;
}

export type Page = 'start' | 'login' | 'confirm';

function LoginModal(props: LoginModalProps) {
  const user = useSelector(loggedInUser());
  const [page, setPage] = useState<Page>(props.initialPage || 'start');
  const { t } = useTranslation('login');

  const handleModalClose = (status: LoginModalResult['status']) => {
    props.closeModal(status ? { status } : undefined);
  };

  if (user) {
    handleModalClose('success');
  }

  const handleForgotPassword = () => {
    props.closeModal(undefined);
  };

  function renderSSO() {
    return (
      <div className="login__page-option">
        <h3>{t('ssoHeader')}</h3>
        <LoginButtons source="modal" />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Modal
        title={t('title')}
        isOpen={true}
        onRequestClose={() => handleModalClose('failed')}
        animationOnOpen="slide-from-bottom"
        className="login-modal"
        width="large"
      >
        <div className="login__content">
          <div className={`login__page login__page-options show`}>
            {page === 'start' && <LoginSelectApplication next={() => setPage('login')} />}
            {page === 'login' && (
              <>
                <PasswordLogin
                  loginWithPassword={props.userService.loginWithPassword}
                  handleForgotPassword={handleForgotPassword}
                  source="modal"
                />
                <div className="login__page-divider" />
                {renderSSO()}
              </>
            )}
            {page === 'confirm' && (
              <div className="login__page-option">
                <h3>{t('email-sent-title')}</h3>
                <p>{t('email-sent-description')}</p>
                <ActionButton theme="light" onClick={() => setPage('login')}>
                  {t('backToLogin')}
                </ActionButton>
              </div>
            )}
          </div>
          {page !== 'confirm' && (
            <Link onClick={() => handleModalClose('failed')} to={registerRoute()} className="back-link">
              {t('register')}
            </Link>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default LoginModal;
